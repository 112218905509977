import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import packageJson from '../../../package.json';
import { ROUTES } from '../constants';

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <footer className="gz-footer">
        <Container>
          <div className="d-flex justify-content-between gz-footer-inner">
            <div> Copyright © {currentYear} GameZone Limited. All rights reserved.</div>
            <div> v{packageJson.version}</div>
            <div className="text-end">
              <Link target="_blank" to={ROUTES.PRIVACY_POLICY}>Privacy Policy </Link>
              <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
              <Link target="_blank" to={ROUTES.COOKIES_POLICY}>Cookies Policy </Link>
              <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
              <Link target="_blank" to={ROUTES.TERM_OF_USES}>Terms of Use </Link>
            </div>
          </div>
        </Container>
      </footer>
    </>
  );
}