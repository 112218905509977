import React, { useCallback, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { connectorLocalStorageKey } from '../../components/literals';
import { connectors } from '../../constants';
import { useActiveWeb3React } from '../../hook';
import useAuth from '../../hook/useAuth';
import { ACTION_CONST } from '../constants';
import Web3Helper from '../utils/walletExtensionUtils';

const ConnectWalletModal = ({ onDismiss }) => {
    const dispatch = useDispatch();
    const { login } = useAuth();
    const { account, library, error, chainId } = useActiveWeb3React()

    const handleConnectClick = useCallback((walletConfig) => {
        try {
            login(walletConfig.connectorId);
            window.localStorage.setItem(connectorLocalStorageKey, walletConfig.connectorId);
        } catch (error) {
            console.log(error);
        }
    }, [])

    useEffect(() => {
        if (error)
            return;
        if (account && library && library.provider) {
            const web3 = new Web3Helper(library.provider, account, chainId)
            dispatch({
                type: ACTION_CONST.ENABLE_WALLET_SUCCESS,
                data: web3
            })
            onDismiss();
            return;
        }
    }, [library, account, error])

    return <Modal className='gz-modal connect-wallet' show={true} onHide={onDismiss} centered>
        <Modal.Header closeButton>
            <Modal.Title>Connect to wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5 pt-4 pb-3">
            {connectors.map((entry, key) => {
                if (entry.enable === true) {
                    return (
                        <Button key={key} className="w-100 mb-2" onClick={() => handleConnectClick(entry)}>
                            <img className="me-2" src={entry.icon} height="26" alt={entry.connectorId} />
                            <span>{entry.title}</span>
                        </Button>
                    )
                }
            })}
        </Modal.Body>
    </Modal>;
}

export default ConnectWalletModal;


