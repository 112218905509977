import React, { useState } from "react";

const Search = ({ onSearch, placeholder = "Search" }) => {
    const [search, setSearch] = useState("");

    const onInputChange = value => {
        setSearch(value);
        onSearch(value);
    };
    return (
        <div className="table-input-search d-flex align-items-center">
            <span className="mdi mdi-magnify ms-3 me-1"></span>
            <input
                type="text"
                className="form-control"
                placeholder={placeholder}
                value={search}
                onChange={e => onInputChange(e.target.value)}
            />
        </div>
    );
};

export default Search;
