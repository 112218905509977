import React from "react";
import { Col, Container, Image, ProgressBar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useOpeningProjects } from "../../../hook/useState";

const OpeningProjectsComponent = () => {
  const openingProjects = useOpeningProjects();

  return (
    <>
      <section className={`gz-projects open ${openingProjects.length > 0 ? '' : 'no-data'}`}>
        <Container>
          <div className="gz-projects-heading">Projects <br />Open now</div>
          {openingProjects.length > 0 ?
            <Row className="gx-md-5 justify-content-xl-center">
              {
                openingProjects.map((item, key) => {
                  return (
                    <Col xl={4} lg={6} md={6} className="text-center" key={key}>
                      <div className="gz-project-card gz-project-item mb-4 mt-xl-3">
                        <div className="gz-project-header">
                          <span className="pp-status-open">Open</span>
                          <span className="symbol">{item.symbol}</span>
                        </div>
                        <div className="gz-project-body">
                          <Link title={item.name} to={`projects/${item.contract}`} className="text-decoration-none d-block" >
                            <div className="gz-project-logo">
                              <img src={item.logo} alt="" />
                              {
                                item.athMultiplier &&
                                <div className="pp-top-tag">
                                  <div><small>ATH</small></div>
                                  <div><b>{item.athMultiplier}x</b></div>
                                </div>
                              }
                            </div>
                            <div className="gz-project-name">{item.name}</div>
                          </Link>
                        </div>
                        <div className="gz-project-detail">
                          <div className="gz-project-description">{item.description}</div>
                          {
                            item.button_name &&
                            <div className="text-center mb-4 mx-5">
                              <a href={item.button_url} target="blank" className="btn btn-primary btn-sm mx-auto" style={{ width: '80%' }}>
                                <span>{item.button_name}</span>
                              </a>
                            </div>
                          }
                          <ul className="gz-project-info">
                            <li>
                              <span>Swap rate</span><br />
                              <b className="nowrap text-white">
                                1 {item.symbol} = {(item["rate"])} {item["projectTokenSymbol"]}
                              </b>
                            </li>
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <div>
                                  <span>Cap</span><br />
                                  <b className="nowrap text-white">
                                    {`${item.maxTotalParticipationAllocated || 0} ${item.symbol || ""}`}
                                  </b>
                                </div>
                                <div className="pe-3">
                                  <span>Access</span><br />
                                  <b className="nowrap text-white">
                                    {item.isPrivate ? "Private" : "Public"}
                                  </b>
                                </div>
                              </div>
                            </li>
                            <li>
                              {
                                item.state == 'O' ?
                                  <div className={`gz-progress-wrap ${item.state == 'O' ? 'disabled' : ''}`}>
                                    <div className="d-flex justify-content-between align-items-center">
                                      <span>Progress</span>
                                      {item.state != 'O' && <span><strong className="text-white me-1">{item['totalCountUserParticipated']}</strong> Participants</span>}
                                    </div>
                                    <div className='gz-progress'>
                                      <div className="gz-progress-percent">
                                        <ProgressBar animated striped now={(item.totalFundParticipated / item.maxTotalParticipationAllocated || 0) * 100} />
                                      </div>
                                      <div className="gz-progress-label">
                                        <span>Allocation round</span>
                                        <span>{item['totalCountUserParticipated']} Participants</span>
                                      </div>
                                    </div>
                                  </div>
                                  :
                                  <>
                                    <div className={'gz-progress-wrap'}>
                                      <div className="d-flex justify-content-between align-items-center">
                                        <span>Progress</span>
                                        <span><strong className="text-white me-1">{item['totalCountUserParticipated']}</strong>Participants</span>
                                      </div>
                                      <div className='gz-progress'>
                                        <div className="gz-progress-percent">
                                          <ProgressBar animated striped now={(item.totalFundParticipated / item.maxTotalParticipationAllocated || 0) * 100} />
                                        </div>
                                        <div className="gz-progress-label">
                                          <span>{((item.totalFundParticipated / item.maxTotalParticipationAllocated || 0) * 100).toFixed(2)}%</span>
                                          {item.state == 'O' && <span>{item['totalCountUserParticipated']} Participants</span>}
                                          <span>{item.totalFundParticipated.toFixed(4)}/{item.maxTotalParticipationAllocated}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                              }
                            </li>
                          </ul>
                          <div className="gz-project-socials">
                            <div className="gz-project-socials-inner">
                              {
                                item.pancakeswap &&
                                <a href={item.pancakeswap} target="_blank" rel="noopener noreferrer">
                                  <Image height="18" src="/images/pancakeswap-cake-logo.png" alt="" />
                                </a>
                              }
                              {
                                item.website &&
                                <a href={item.website} target="_blank" rel="noopener noreferrer"><i className="fas fa-globe"></i></a>
                              }
                              {
                                item.twitter &&
                                <a href={item.twitter} target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
                              }
                              {
                                item.medium &&
                                <a href={item.medium} target="_blank" rel="noopener noreferrer"><i className="fab fa-medium-m"></i></a>
                              }
                              {
                                item.telegram &&
                                <a href={item.telegram} target="_blank" rel="noopener noreferrer"><i className="fab fa-telegram"></i></a>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  )
                })
              }
            </Row> :
            <div className="text-center">
              <div className="mb-3">No projects currently open</div>
              <Image className="gz-no-data" alt='' src="/images/robot.png" style={{ width: '60vw', marginBottom: -150 }} />
            </div>
          }
        </Container>
      </section>
    </>
  );
};

export default OpeningProjectsComponent;
