import React from "react";
import moment from 'moment';
import GZCard from "../../../shared/components/card";
import { Table } from "react-bootstrap";

const ScheduleTabComponent = (props) => {
  return (
    <GZCard title="Schedule">
      <Table responsive className="mb-0">
        <tbody>
          <tr className="card-header">
            <td className="text-white">Round</td>
            <td className="text-white">Opens</td>
            <td className="text-white">Closes</td>
          </tr>
          {props.roundInfo.length > 0 &&
            props.roundInfo.map((item, key) =>
            (
              <tr key={key}>
                <td>{item.round}</td>
                <td>
                  <h5 className="mb-0">{moment(item.opens * 1000 || 0).utc().format('MM-DD HH:mm')} UTC</h5>
                </td>
                <td>
                  <h5 className="mb-0">{moment(item.closes * 1000 || 0).utc().format('MM-DD HH:mm')} UTC</h5>
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </GZCard>
  );
};

export default ScheduleTabComponent;
