import * as $ from 'jquery';
import React, { useEffect } from "react";
import { Container } from "react-bootstrap";

const TermOfUsePage = () => {
    useEffect(() => {
        $('body').addClass('no-bg');
        return (() => {
            $('body').removeClass('no-bg');
        })
    }, [])

    return <div className='gz-page'>
        <Container>
            <div className="text-center mb-5 pb-3">
                <div className='gz-page-title mb-3'>GAMEPAD LIMITED</div>
                <div className='gz-page-description'>Terms and Conditions of <a href="https://gamezone.io/">https://gamezone.io/</a></div>
                <div>Last revised Date: 20<sup>th</sup> May 2022</div>
            </div>
            <>
                <div className='mb-4'>
                    <h3>1. Introduction</h3>
                    <p>These Terms and Conditions (herein referred to as “<strong>Terms</strong>”)
                        govern the use and the conditions of&nbsp;
                        <a href="https://gamezone.io/">https://gamezone.io/</a>&nbsp;(herein referred
                        as “<strong>Website</strong>”), and the Services provided by GamePad Limited
                        (herein referred to as “<strong>GAMEZONE</strong>” or “<strong>we</strong>” or
                        “<strong>us</strong>” or “<strong>ours</strong>”, which shall have the same
                        meaning in these Terms), a company incorporated and registered under the laws
                        of Republic of Seychelles. These Terms constitute a binding and enforceable
                        legal contract between us and our Affiliate and subsidiaries worldwide and
                        you, an end user of the Services (herein referred to as “<strong>you</strong>”
                        or “<strong>user</strong>”) in relation to the Services. You and GAMEZONE are
                        referred to separately as “<strong>Party</strong>” and collectively as “
                        <strong>Parties</strong>”. </p>
                    <p>By accessing, registering, using, or clicking on
                        the Services, and information made available by us via the Website, you hereby
                        accept and agree to all the Terms set forth herein.</p>
                    <p>You are strongly advised to carefully read these Terms as well as the provisions detailed in our
                        Privacy Policy prior to using the Website and our Services. By using the
                        Website and the Services in any capacity, you agree that: (i) You have read
                        and familiarized yourself with these Terms; (ii) You understand these Terms;
                        and (iii) You agree to be bound by these Terms when using the Website. If You
                        do not agree to these Terms, please do not access or use the Website and the
                        Services.</p>
                    <p>We reserve the right to modify or amend these Terms, the Website, or
                        any content on either one of the platforms from time to time, including for
                        security, legal, or regulatory reasons, as well as to reflect updates or
                        changes to the Services or functionality of the Website. You are advised to
                        check these Terms periodically to ensure that you are cognizant of the current
                        versions and comply with them. Users of the Website and the Services are bound
                        by these changes which will take immediate effect after the revised versions
                        of these Terms have been published on the Website or the relevant mobile
                        application. Through your continued use of or interaction with the Website,
                        the Services, tools, and information made available on the Website, you hereby
                        agree to be bound by the provisions highlighted in the subsequent versions.</p>
                    <p>We will provide a notification on the Website specifying that changes have been
                        made to these Terms whenever they occur. By accepting the notification, you
                        accept that we have provided you with sufficient notice of any changes. You
                        should seek professional advice regarding any possible legal requirements you
                        must comply with in relation to the use of the Website, the Service or the
                        relevant tools provided by us or the Partners. &nbsp;
                    </p>
                </div>
                <div className='mb-4'>
                    <h3>2. Definitions</h3>
                    <p>
                        <strong>“ADAPAD”&nbsp;</strong>refers to the platform for Cardano Smart Chain
                        Network, which is a platform for retail investment into tokens offered via
                        IDO. Please find further details at&nbsp;
                        <a href="https://adapad.io/">https://adapad.io/</a>
                    </p>
                    <p>
                        <strong>"Affiliate"&nbsp;</strong>refers to in relation in any party, any
                        other company which, directly or indirectly, (i) is controlled by that party,
                        (ii) controls that party, or (iii) is under common control with that party,
                        and in respect of us, shall also include any fund, limited partnership or
                        other collective investment vehicle or other person which is managed or
                        advised by us.
                    </p>
                    <p>
                        <strong>"AML"&nbsp;</strong>refers to anti-money laundering.
                    </p>
                    <p>
                        <strong>"Applicable Laws"&nbsp;</strong>refers to acts, statutes, regulations,
                        ordinances, treaties, guidelines, and policies issued by governmental
                        organizations or regulatory bodies, including, but not limited to, the
                        governing law stipulated under Laws of the British Virgin Islands.
                    </p>
                    <p>
                        <strong>“GAMEZONE Platform”&nbsp;</strong>refers to our Website, or platform
                        for high-quality blockchain gaming projects where the Project is officially
                        launched, and makes available its native GAMEZONE Tokens. “
                    </p>
                    <p>
                        <strong>GAMEZONE Token(s)</strong>” refers to $GZONE Token, a blockchain-based
                        token which is issued, stored, transferred, transacted, launched as a BEP-20
                        asset on the Binance Smart Chain Network. Users need to hold and Stake
                        GAMEZONE Tokens to participate in any Services on the GAMEZONE Platform.
                    </p>
                    <p>
                        <strong>"CFT"&nbsp;</strong>refers to combating the financing of terrorism.
                    </p>
                    <p>
                        <strong>"Confidential Information"&nbsp;</strong>refers to any non-public,
                        proprietary information or documents of or related to the user or us (whether
                        in writing, orally or by any other means) by or on behalf of the user to us
                        and which if disclose in tangible or intangible form is marked confidential
                        (including in visual, oral, or electronic form) relating to us or any other
                        user that was previously an user, which is provided or disclosed through us
                        (or to any employees or agents) in connection with the use or participate in
                        the services.
                    </p>
                    <p>
                        <strong>"Content"</strong>&nbsp;refers to all content generated by us,
                        including logos, identifying marks, images, illustrations, designs, icons,
                        photographs, videos, text, any written or multimedia materials, services,
                        advertisements, software, code, data, files, archives, folders, or available
                        downloads on the GAMEZONE Platform.
                    </p>
                    <p>
                        <strong>“Cookies”&nbsp;</strong>refers to the small text files that are placed
                        on your computer by the website that you visit. They are widely used in order
                        to make websites work, or work more efficiently, as well as to provide
                        information to the owner of the site.
                    </p>
                    <p>
                        <strong>“CrossSwap”</strong>&nbsp;refers to the cross-chain swap designed to
                        unify the trading service on one platform. Please find further details
                        at&nbsp;<a href="https://crossswap.com/">https://crossswap.com/</a>.
                    </p>
                    <p>
                        <strong>“CrossWallet”</strong>&nbsp;refers to the non-custodian wallet service
                        that holds any digital asset you prefer to hold, trade, or send, from tokens
                        to NFTs, which it works seamlessly to move between different blockchain and
                        works on any device, either mobile or web. Please find further details
                        at&nbsp;<a href="https://crosswallet.app/">https://crosswallet.app/</a>.
                    </p>
                    <p><strong>“ETHPAD”&nbsp;</strong>refers to the platform for Ethereum Smart Chain
                        Network, which is a platform for retail investment into tokens offered via
                        IDO. Please find further details at&nbsp;
                        <a href="https://ethpad.network/">https://ethpad.network/</a>.</p>
                    <p>
                        <strong>"Gate.io"</strong> is a crypto trading platform both centralized and
                        decentralized. Please find further details at&nbsp;
                        <a href="https://www.gate.io/">https://www.gate.io/</a>.
                    </p>
                    <p>
                        <strong>“IGO(s)”&nbsp;</strong>refers to Initial Game Offerings
                    </p>
                    <p>
                        <strong>“INO(s)”&nbsp;</strong>refers to Initial Non-Fungible Token Offerings,
                        which the GAMEZONE Platform’s user may benefit from rarer items, more tokens,
                        or more favorable ratio of item to tokens, which the details are provided on
                        the Website.
                    </p>
                    <p>
                        <strong>"MEXC Global"</strong> is a crypto trading platform.
                        Please find further details at&nbsp;
                        <a href="https://www.mexc.com/">https://www.mexc.com/</a>.
                    </p>
                    <p>
                        <strong>“NFTLaunch”</strong>&nbsp;refers to the platform for deflationary NFT
                        Launchpad which is the first platform to offer zero gas wars, fair
                        distribution and free NFT airdrops of new and upcoming NFT projects. Please
                        find further details at&nbsp;
                        <a href="https://nftlaunch.network/">https://nftlaunch.network/</a>.
                    </p>
                    <p>
                        <strong>“PancakeSwap”&nbsp;</strong>refers to the Decentralized Exchange of
                        the PancakeSwap ecosystem. Please find further details at&nbsp;
                        <a href="https://pancakeswap.finance/">https://pancakeswap.finance/</a>.
                    </p>
                    <p>
                        <strong>“Partners’’</strong>&nbsp;refer to us, BSCPAD, ETHPAD, PolyPad,
                        Gate.io, ADAPAD, VELASPAD, NFTLaunch, CrossWallet, CrossSwap,&nbsp; and
                        WagyuSwap.
                    </p>
                    <p>
                        <strong>“PolyPad”</strong>&nbsp;refers to the platform that
                        facilitates token swaps, and where the Project is officially launched, and
                        makes available its native Tokens (“Pool”) to be distributed to users in the
                        allocation rounds.&nbsp; Please find further details at&nbsp;&nbsp;
                        <a href="https://polypad.com/">https://polypad.com/</a>.
                    </p>
                    <p>
                        <strong>"Prohibited Jurisdictions"&nbsp;</strong>specifically refer to
                        Albania, Barbados, Botswana, Burkina Faso, Cambodia, Democratic People’s
                        Republic of Korea, Haiti, Iran, Jamaica, Morocco, Myanmar (Burma), Nicaragua,
                        Pakistan, Panama, Philippines, Senegal, South Sudan, Syria, Thailand, Uganda,
                        Yemen, Zimbabwe, and the United States of America.
                    </p>
                    <p>
                        <strong>“Project”</strong> refers to the game project being launched for Initial Game Offering (“IGO”)
                        and Initial NFT Offerings (“INO”) event on GAMEZONE Platform.
                    </p>
                    <p>
                        <strong>“Services</strong>” refer to the services provided to the users through
                        the Website, which include the accessibility to the new games partnered with
                        GAMEZONE as well as a qualifier to participate in IGOs and INOs, Staking
                        services, and other relevant services available through the Platform, which
                        may be subject to periodic revision.
                    </p>
                    <p>
                        <strong>“Staking or Stake”</strong> refers to the staking or deposit service in which
                        the users can delegate or deposit the user’s GAMEZONE Token in exchange for
                        opportunities GAMEZONE offers as stipulated on the Website, which may be
                        subject to periodic revision.
                    </p>
                    <p>
                        <strong>“UniSwap”&nbsp;</strong>refers to the Decentralized Exchange built on
                        DeFi Apps. Please find further details at&nbsp;
                        <a href="https://app.uniswap.org/">https://app.uniswap.org/</a>.
                    </p>
                    <p>
                        <strong>“VELASPAD”&nbsp;</strong>refers to the platform for Velas Chain
                        Network, which is a platform for retail investment into tokens offered via
                        IDO. Please find further details at&nbsp;
                        <a href="https://velaspad.io/">https://velaspad.io/</a>.
                    </p>
                    <p>
                        <strong>"Wallet(s)"&nbsp;</strong>refers to a digital wallet which must be
                        compatible with the Binance Smart Chain Network, such as MetaMask or Trust
                        wallet. &nbsp;
                    </p>
                </div>
                <div className='mb-4'>
                    <h3>3. General Provisions</h3>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>3.1 Contractual Relationship</strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        These Terms constitute a valid and binding agreement between the Parties.
                        The binding obligations stipulated in these Terms are enforceable.
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>3.2 Revision and Amendments</strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        We reserve the right to revise, amend, or update any clauses and provisions
                        stipulated in these Terms in our sole discretion at any time. We may notify
                        the revision or amendment of such clauses or provisions by updating these
                        Terms and specify the&nbsp;<em>'Last Revised Date</em>' displayed on a page
                        of Terms and Conditions. Any revisions and updates on these Terms will be
                        automatically effective upon their publication on the Platform, which
                        includes the Website. Therefore, your continuity of accessing or using the
                        GAMEZONE Platform, and/or Services will be deemed that you agree to have
                        read, understood, and accepted all revised terms and provisions. If you do
                        not agree on any revised or updated terms and provisions, you should
                        immediately stop accessing the Website or using the Services. You are
                        encouraged to frequently and carefully review these Terms to ensure that you
                        understand these Terms.
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>3.3 Privacy Policy</strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        You acknowledge and confirm that you have read, understood, and agreed to
                        provisions stipulated in the Website’s Privacy Policy, which will explain
                        how we treat your information and protect your privacy when accessing or
                        using the GAMEZONE Platform.
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        By using GAMEZONE Platform, you hereby agree that we may collect, use your
                        information, and anonymised data pertaining to your use of the Services for
                        analytics, trends’ identification, and purpose of statistics to further
                        enhance the effectiveness and efficiency of the Services.
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        You hereby expressly authorise us to disclose any and all information
                        relating to you in our possession to any law enforcement or government
                        officials upon the request by the court order.
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>3.4 Links to and from the Website</strong>
                    </p>
                    <p style={{ paddingLeft: 80 }}>
                        3.4.1 You may, through hypertext or other computer links, gain access from
                        the Website to websites operated or made available, or otherwise licensed by
                        persons other than us (“<strong>Third Party Services</strong>”). Such links
                        are provided for your convenience.
                    </p>
                    <p style={{ paddingLeft: 80 }}>
                        3.4.2 A link from Third Party Services does not mean that we endorse or
                        approve the content on such a website or does not mean that we are an
                        operator of that website. You understand that you are solely responsible for
                        determining the extent to which you may use or rely upon any content at any
                        other Third Party Services websites which you have accessed from the
                        Website. We have no control over the content of these sites or resources and
                        accept no liability for them or for any loss or damage that may arise from
                        your use of them.
                    </p>
                    <p style={{ paddingLeft: 80 }}>
                        3.4.3 We assume no responsibility for the use of, or inability to use, any
                        Third Party Services’ software, other materials, or contents posted and/or
                        uploaded on such a website and we will have no liability whatsoever to any
                        person or entity for any inaccuracy or incompleteness of such third-party
                        content. All intellectual property rights in and to Third Party Services are
                        property of the respective third parties.
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            3.5 Disclaimer for Accessibility of the Website and the Services
                        </strong>
                    </p>
                    <p style={{ paddingLeft: 80 }}>
                        3.5.1 The Website merely facilitates the user’s accessibility to the Project
                        and does not provide you with any warranty or representation whatsoever
                        regarding its quality, value, specification, fitness for the purpose,
                        completeness or accuracy of its technology or infrastructure of such
                        Project.
                    </p>
                    <p style={{ paddingLeft: 80 }}>
                        3.5.2 We will make all commercially reasonable attempts to facilitate
                        information about the Project on the GAMEZONE Platform. However, you hereby
                        acknowledge that we do not guarantee the accuracy, timeliness, or
                        completeness of such information, and do not provide any warranty in
                        connection with your use or reliance on such information. You agree that
                        your use of the Project information will be at all your own risk. We will
                        not be liable to you in any manner for the termination, interruption, delay,
                        or inaccuracy of any Project information launched on the GAMEZONE Platform.
                    </p>
                    <p style={{ paddingLeft: 80 }}>
                        3.5.3 To the extent permitted by applicable laws, the Website and our
                        Services are provided on an ‘AS IS’ and ‘AS AVAILABLE’ basis. We do not
                        warrant that the features, and functions contained on the Website and the
                        Services will satisfy your preferences you hereby agree and acknowledge that
                        your access and use of the Website and the Services are at your own risk,
                        and you will be liable for any responsibility, consequences that may arise
                        out of or in connection with the usage or accessibility of the Website and
                        the Services. You expressly agree that we will have absolutely no liability
                        in this regard.
                    </p>
                    <p style={{ paddingLeft: 80 }}>
                        3.5.4 You hereby agree and acknowledge that the Website may contain links to
                        a third-party website or services that are not owned or even controlled by
                        us, thus we will hold no responsibility for the content or services, goods,
                        or activities provided by such third-party website. You further agree that
                        we have no liability directly or indirectly for any damage, loss, or
                        consequence arising out of or in connection with the usage or reliance of
                        any content, material, services available through the third-party website.
                    </p>
                    <p style={{ paddingLeft: 80 }}>
                        3.5.5 We reserve the right to limit the availability of the Website to any
                        person, geographic area, or jurisdiction we so desire and/or terminate your
                        access to and use of the Website and the Services, at any time and in our
                        sole discretion.
                    </p>
                    <p style={{ paddingLeft: 80 }}>
                        3.5.6 We may, at our sole discretion, impose limits or restrictions on the
                        use you make of the Website. Further, for commercial, security, technical,
                        maintenance, legal or regulatory reasons, or due to any breach of these
                        Terms, we may limit your access to the Website and the Services at any time
                        and without notice to you.
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>3.6 Information</strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        You acknowledge that you are solely responsible for any submissions of all
                        contents, remarks, suggestions, ideas, materials, feedbacks, or other
                        information, including bug reports in relation to the Services provided
                        through the Website including any submission to our social media platforms
                        such as Twitter and Telegram, and you, not us, have full responsibility for
                        such submissions, including their accuracy, legality, reliability,
                        appropriateness, originality, and copyright. We shall reply on the
                        information you have provided and will not verify it&nbsp; foregoing, we
                        have the right to refuse to post, remove, edit, or abridge any submission
                        for any reason and to freely use, copy, disclose, publish, display, or
                        exploit such submission as we deem necessary without any payment of royalty,
                        acknowledgement prior to consent, we may retain copies of all information
                        materials relevant to the Service.
                    </p>
                </div>
                <div className='mb-4'>
                    <h3>4. Intellectual Property</h3>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            4.1&nbsp;All present and future copyright, title, interests in and to the
                            Services, registered and unregistered trademarks, design rights,
                            unregistered designs, database rights and all other present and future
                            intellectual property rights and rights in the nature of intellectual
                            property rights that exist in or in relation to the use and access of the
                            Website and Services are owned by or otherwise licensed to us. Subject to
                            your compliance with these Terms, we grant you a non-exclusive, non-sub
                            license, and any limited license to merely use or access the Website and
                            the Services in the permitted hereunder.
                        </strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            4.2 Except as expressly stated in these Terms, nothing in these Terms
                            should be construed as conferring any right in or license to us or any
                            other third party’s intellectual rights.
                        </strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            4.3 If and to the extent that any such intellectual property rights are
                            vested in you by operation of law or otherwise, you agree to do any and
                            all such acts and execute any and all such documents as we may reasonably
                            request in order to assign such intellectual property rights back to us.
                        </strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            4.4 You agree and acknowledge that all Website content must not be copied
                            or reproduced, modified, redistributed, used, created for derivative
                            works, or otherwise dealt with for any other reason without being granted
                            a written consent from us.
                        </strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            4.5 Third parties participating on the Website may permit us to utilise
                            trademarks, copyrighted material, and other intellectual property
                            associated with their businesses. We will not warrant or represent that
                            the content of the Website does not infringe the rights of any third party
                        </strong>
                    </p>
                </div>

                <div className='mb-4'>
                    <h3>5. Participation in the Services</h3>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            5.1 By using or accessing the Services, you represent and warrant that you
                            understand that there are inherent risks associated with digital
                            currencies, and the underlying technologies, including, without
                            limitation, cryptography and blockchain, and you agree that we, the
                            community, and open-source code contributors are not responsible for any
                            loss or damage associated with these risks.
                        </strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            5.2 You specifically acknowledge and agree to follow the conditions
                            stipulated herein the Website, such as tier-based system,
                            Staking/Unstaking and other conditions which we may update from time to
                            time.
                        </strong>
                    </p>
                </div>

                <div className='mb-4'>
                    <h3>6. Identity Verification through KYC/AML/CFT Process</h3>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            6.1 As a software development company, we have no role in enforcing
                            KYC/AML/CFT by default, however, we have mandatory requirements for
                            KYC/AML/CFT identification verification tools for the Project fundraising
                            companies through us to enforce on users. We are implementing KYC/AML/CFT
                            tools into our launchpad through&nbsp;
                            <a href="https://kycaid.com/">https://kycaid.com/</a>.
                        </strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            6.2 The use of KYC/AML/CFT tools on us by the Project fundraising
                            companies using the Services on the GAMEZONE Platform is not the
                            discretion of said entitles, and they must require you to complete
                            KYC/AML/CFT verification process before participating in any Projects.
                        </strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            6.3 Although we make no warranty as to the merit, legality, or juridical
                            nature of any Project, we nonetheless understand the need of the Project
                            to require KYC/AML/CFT verification on its blockchain games users.
                            Therefore, we reserve the right:
                        </strong>
                    </p>
                    <p style={{ paddingLeft: 80 }}>
                        6.3.1 at any time, to ask for your personal information, name-surname,
                        birthday,-e- mail address, nationality, location, government identification
                        number (identification card/passport number and date of identification
                        card/passport issuing), telegram username, Wallet address, and any
                        KYC/AML/CFT documentation with the liveness test that it deems necessary to
                        determine the identity and location of an user, and reserve the right to
                        restrict Service and payment until the user’s identity is sufficiently
                        determined;
                    </p>
                    <p style={{ paddingLeft: 80 }}>
                        6.3.2 The liveness test shall require you to take a photo of your government
                        identification with your phone or camera. and then take a photo of (i.e., a
                        selfie of your face) holding your ID document and a piece of paper you wrote
                        on next to your face (not covering your face), in which you shall write down
                        the (1) Game Zone, (2) the current date, and (3) the last 4 characters of
                        your Wallet address”. If you have any questions, please find details about
                        KYC/AML/CFT process here:&nbsp;
                        <a href="https://bscpad.medium.com/bscpad-kyc-process-16e6a5557138">
                            https://bscpad.medium.com/bscpad-kyc-process-16e6a5557138
                        </a>
                        .
                    </p>
                    <p style={{ paddingLeft: 80 }}>
                        6.3.3 to share the submitted KYC/AML/CFT information and documentation to
                        the third parties to verify the authenticity of the submitted information,
                        and the end user (you) giving the consent to share such information by using
                        the Services; and
                    </p>
                    <p style={{ paddingLeft: 80 }}>
                        6.3.4 to reject the use of the Services if we have the reasonable ground to
                        believe that they are found to be in violation of relevant and applicable
                        AML/CFT laws and regulations, and to cooperate with the competent
                        authorities or any investigation when and if necessary, upon the valid
                        request by the court order.
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            6.4 We expressly prohibit and reject the use of the Service for any form
                            of illicit activity, including money laundering, terrorist financing or
                            trade sanctions violations, consistent with various jurisdictions’ laws,
                            regulations and norms. To that end, the Services are not offered to
                            individuals or entities on any Politically Exposed Persons (“PEP”) lists,
                            or subject to any United States, European Union, or other global sanctions
                            or watch lists. By using the Services, you represent that you are not on
                            any of such lists.
                        </strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            6.5 You fully acknowledge that your information and KYC/AML/CFT
                            documentation may be disclosed to government agencies or regulators upon a
                            valid request of the court order. Once you have decided to participate in
                            any Project and start staking your GAMEZONE Token, you must ensure that
                            all information provided to us is complete, accurate, and updated in a
                            timely manner. We will rely on the information you provided and should
                            there be any reasonable grounds for us to believe that the partial or the
                            whole of your information provided to us is incomplete, or incorrect, or
                            outdated, we reserve the right to send you a notice to demand correction,
                            or to delete such information directly, and, as the case may be, to
                            disable you to access to all or part of the Website and the Services.
                        </strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            6.6 If we have a reasonable ground to believe that any user transacts or
                            uses the Services by using digital currencies derived from any suspicious
                            illegal activities, we shall be entitled to block your accessibility as
                            necessary. We will hold no liability to such users for any damage, or loss
                            arising out of or in connection with this manner herein. Please note that
                            any attempt to circumvent our measures set out in this Clause 6 will also
                            result in a similar action.
                        </strong>
                    </p>
                </div>

                <div className='mb-4'>
                    <h3>7. Misuse of the Website</h3>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            7.1 In the event of any misuse and/or abuse of the Website or breach of
                            any provision in these Terms, we reserve the right to block your access to
                            the Website and other Services until the matter is solved.
                        </strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            7.2 Use of the Website for transmission, publication or storage of any
                            material on or via the Website which is in violation of any applicable
                            laws or regulations or any third-party’s rights is strictly prohibited,
                            including but not limited to the use of the Website or the transmission,
                            distribution, publication or storage any material on or via the Website in
                            a matter or for the purpose which infringes copyright, trademark, trade
                            secret or other intellectual property rights, is obscene or harmful to
                            minors or constitutes an illegal act or harassment, is libellous or
                            defamatory, violates any privacy or data protections laws, is fraudulent
                            or breaches any exchange control laws
                        </strong>
                        .
                    </p>
                </div>

                <div className='mb-4'>
                    <h3>8. Representations and Warranties</h3>
                    You hereby agree to make the following representations and warranties by
                    accessing to the Website and/or using the Services:
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            8.1 You have full capacity and authority under the applicable laws to
                            agree and bind yourself to these Terms.
                        </strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>8.2 You are eighteen years of age or older.</strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            8.3 You are not a citizen or a resident of the Prohibited Jurisdictions,
                            and you do not have any relevant connection with any of the Prohibited
                            Jurisdictions.
                        </strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            8.4 You are aware and agree to comply with all applicable domestic and
                            international laws, statutes, ordinances, and regulations applicable to
                            your use of the Website and the Services. Your use of the Website and the
                            Services are not for any unlawful or illegal purposes, including but not
                            limited to the usage against the copyright laws and, AML/CFT laws.
                        </strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            8.5 You are the exclusive owner of GAMEZONE Token and your other
                            cryptocurrencies held in your Wallet. The tokens maintained in your
                            Wallets are not and will not be derived from money laundering, terrorist
                            financing, fraud, or any other illegal activities under any applicable
                            laws. You further hereby acknowledge and agree that we will not be
                            responsible for actions taken by you that result in the loss or
                            destruction of the value of the tokens and rewards you hold in the Wallet.
                        </strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            8.6 You validly undertake any action or enter into any transaction with
                            regard to these Terms. You are solely responsible for the use of the
                            Website and the Services for all activities, or transactions that occur on
                            or through your user account on GAMEZONE Platform.
                        </strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            8.7 You will provide only accurate, complete, and up-to-date information
                            and documents, if any, for the purposes of accessing or using or
                            participating in our Services on the GAMEZONE Platform. You will further
                            agree to put your effort to ensure that the confidentiality of your
                            personal or credential information, including your Wallet address is
                            restricted, and safely maintained to your device you use to access the
                            Website.
                        </strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            8.8 You acknowledged and agreed that if you lose access to the Wallet that
                            you connected with the GAMEZONE Platform, we will not be able to help you
                            recover the loss, or transfer of Project’s tokens or any GAMEZONE Token
                            back to your Wallet. It will be your solely responsibility to manage your
                            cryptocurrencies stored in your Wallet, and your private key.
                        </strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            8.9 You will be responsible for obtaining the data network access
                            necessary to use the Website. Your network's data and rates and fees may
                            apply if you access or use the Website from a wireless-enabled device, and
                            you will be responsible for such rates and fees.
                        </strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            8.10 You understand and are aware of the risks associated with accessing
                            or using or participating in the Services, and you will be fully liable at
                            your own risk.
                        </strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            8.11 You are aware that you are subject to tax regulations in the
                            jurisdiction you reside in and will be fully responsible for filling or
                            reporting any taxes and paying them as required by the Applicable Laws. We
                            will not be liable to compensate you for your tax obligations or advise
                            you in relation to your tax obligations. Any uncertainties and
                            unpredictable matters in tax legislation with respect to any tokens may
                            expose you to any unknown or unforeseeable tax implications associated
                            with your holding of tokens and the use of the Services for which we will
                            have no liability. Moreover, you will not hold us liable for any expenses
                            or losses resulting from unknown or unforeseeable tax implications.
                        </strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            8.12 You will not breach any of the provisions stipulated in these Terms,
                            the Privacy Policy, or any Applicable Laws in any relevant jurisdictions.
                        </strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            8.13 You will not use the Website and the Services in one of any following
                            manners, except as expressly permitted in these Terms, or at our
                            discretion.
                        </strong>
                    </p>
                    <p style={{ paddingLeft: 80 }}>You will not:</p>
                    <p style={{ paddingLeft: 120 }}>
                        (a) infringe any property rights, including but not limited to copyrights,
                        patents, trademarks, or trade secrets of us.
                    </p>
                    <p style={{ paddingLeft: 120 }}>
                        (b) use the Website or the Services to transmit any data or send or upload
                        any material or content that contains viruses, Trojan horses, worms,
                        time-bombs, keystroke loggers, spyware, adware, or any other harmful
                        programmes or similar computer code designed to adversely affect the
                        operation of the Website and/or the Services.
                    </p>
                    <p style={{ paddingLeft: 120 }}>
                        (c) expressly or impliedly, use the Website and the Services in the manner
                        that is deemed unlawful, offensive, malicious, threatening, libellous,
                        defamatory, obscene, or otherwise objectionable or violates these Terms, or
                        any other party's intellectual property.
                    </p>
                    <p style={{ paddingLeft: 120 }}>
                        (d) modify, make any back-up or archival copies of the Platform or any part
                        thereof including disassembling, and you will also not adapt, hack the
                        Website or modify another website to falsely imply that it is associated
                        with the Website falsely.
                    </p>
                    <p style={{ paddingLeft: 120 }}>
                        (f) crawl, scrape, or otherwise cache any content from the Website, and you
                        agree not to use any automated data collection methods, data mining, robots,
                        or scraping or any data gathering methods of any kind on the Website.
                    </p>
                    <p style={{ paddingLeft: 120 }}>
                        (g) use the Website or any of its contents for advertising or soliciting,
                        for any other commercial, political, or religious purpose, or to compete,
                        either directly or indirectly with us.
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            8.14 You will defend, indemnify, and not hold us, the Partners, our
                            Affiliate, each of our respective employees, officers, directors, and
                            representatives liable to and against any claims, damages, losses,
                            liabilities, costs, and expenses (including reasonable attorney's fees),
                            arising out of or relating to any third-party claim concerning these
                            Terms, or your use of our Services in violation of these Terms and
                            Applicable Laws.
                        </strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            8.15 Each of the user’s Representations and warranties under this Clause 8
                            shall survive and continue to remain in full force and effect after the
                            termination and/or the expiration of these Terms.
                        </strong>
                    </p>
                </div>

                <div className='mb-4'>
                    <h3>9. Risk Disclosure</h3>
                    By accessing the Website or using or participating in our Services, you
                    expressly acknowledge and assume the following risks:
                    <p style={{ paddingLeft: 40 }}>
                        <strong>9.1 Risk of loss in value</strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        Tokens or any digital currencies are not issued by any central banks or
                        national, supra-national, or quasi-national organizations. They are also not
                        backed by any hard assets or other credit. The value of tokens or any
                        digital currencies is affected by several factors, including but not limited
                        to, the total number of tokens or any digital currencies in existence, the
                        continued willingness of market participants to exchange government-issued
                        currency for tokens or digital currencies , purchasers' expectations with
                        respect to the rate of inflation of fiat currencies, purchasers'
                        expectations with respect to the rate of deflation of cryptocurrencies,
                        interest rates, currency exchange rates, cyber theft of cryptocurrencies
                        from online digital wallet providers, or news of such theft from such
                        providers or individuals' digital wallets, investment and trading activities
                        of large investors, monetary policies of the governments, trade
                        restrictions, currency devaluations and revaluations, regulatory measures,
                        the global or regional political, economic or financial events and
                        situations. Thus, all these factors will affect the value of tokens or
                        digital currencies, which may result in the permanent partial or total loss
                        of the value of a particular tokens or digital currencies, a particular
                        tokens or digital currency. No one will be obliged to guarantee the
                        liquidity or the market price of any of the GAMEZONE Token or digital
                        currencies maintained into your Wallets. The volatility and unpredictability
                        of the value of tokens or digital currencies relative to the
                        government-issued currency may result in a significant loss over a short
                        period of time.
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            9.2 The regulatory regime governing tokens or digital currencies
                        </strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        The regulatory framework relating to tokens or digital currencies remains
                        unsettled, and any laws, regulations, or guidelines may be significantly
                        revised and amended which will materially and adversely affect the value of
                        tokens or digital currencies and your services on Website or the GAMEZONE
                        Platform as follows.
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            9.3 Technical and system failure affected the obligations stipulated in
                            these Terms we may experience system failures, unplanned interruptions in
                            the Binance Smart Chain Network or the Services, hardware or software
                            defects, security breaches or other causes that could adversely affect our
                            infrastructure network, which includes the Website and the GAMEZONE
                            Platform.
                        </strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        We are unable to anticipate the occurrence of hacks, cyber-attacks, mining
                        attacks, including but not limited to double-spend attacks, majority mining
                        power attacks and selfish-mining attacks, distributed denial of service
                        attacks or errors, vulnerabilities or defects on the Website, GAMEZONE
                        Token, users' Wallets or any technology, including but not limited to smart
                        contract technology. Also, we are unable to detect the hacks as mentioned
                        earlier, mining attacks, cyber-attacks, distributed denials of service
                        errors, vulnerabilities, or defects in a timely manner and do not have
                        sufficient resources to efficiently cope with multiple service incidents
                        happening simultaneously or in rapid succession.
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        In addition, our network or the services could be disrupted by numerous
                        events, including natural disasters, equipment breakdown, network
                        connectivity downtime, power losses, or even intentional disruptions of our
                        services, such as disruptions caused by software viruses or attacks by
                        unauthorized users, some of which are beyond our control. Although we have
                        taken steps and used our best endeavour against malicious attacks on our
                        appliances or our infrastructure, which are critical for the maintenance of
                        the GAMEZONE Platform and the Services, there can be no assurance that
                        cyber-attacks, such as distributed denials of the Service, will not be
                        attempted in the future, and that our enhanced security measures will be
                        effective. Any significant breach of our security measures or other
                        disruptions resulting in a compromise of the usability, stability and
                        security of our network or the services, including the GAMEZONE Platform,
                        may adversely affect GAMEZONE Token.
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            9.4 We will have no liability for any delay, error, interruption, or
                            failure to perform any obligation under these Terms where the delay or
                            failure is directly or indirectly resulting from any causes beyond our
                            control, including, but not limited to:
                        </strong>
                    </p>
                    <p style={{ paddingLeft: 80 }}>
                        9.4.1 Acts of God, nature, court or government;
                    </p>
                    <p style={{ paddingLeft: 80 }}>
                        9.4.2 Failure or interruption of public or private telecommunication
                        networks, the failure of Binance Smart Chain Network, communication channels
                        or information systems;
                    </p>
                    <p style={{ paddingLeft: 80 }}>
                        9.4.3 Acts or omission of a party for whom we are not responsible;
                    </p>
                    <p style={{ paddingLeft: 80 }}>
                        9.4.4 Delay, failure, or interruption in, or unavailability of, third-party
                        services; and
                    </p>
                    <p style={{ paddingLeft: 80 }}>
                        9.4.5 Strikes, lockouts, labour disputes, wars, terrorist acts and riots.
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            9.5 YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE SERVICES AND THE WEBSITE
                            IS COMPLETELY AT YOUR OWN RISK. THIS CLAUSE IS NOT EXHAUSTIVE AND DOES NOT
                            DISCLOSE ALL THE RISKS ASSOCIATED WITH DIGITAL CURRENCIES AND THE USE OF
                            SERVICES. THEREFORE, YOU ARE RECOMMENDED TO CAREFULLY CONSIDER WHETHER
                            SUCH USE IS SUITABLE FOR YOU IN LIGHT OF YOUR JUDGEMENT, CIRCUMSTANCES,
                            AND FINANCIAL POSITION.
                        </strong>
                    </p>
                </div>

                <div className='mb-4'>
                    <h3>10.&nbsp;&nbsp; Limitation of Liability</h3>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            10.1&nbsp;NOTWITHSTANDING ANY PROVISIONS WITHIN THESE TERMS, IN NO EVENT
                            WILL US, OUR PARTNERS, OUR AFFILIATE, OR OUR EMPLOYEES, AGENTS, OFFICERS,
                            OR DIRECTORS BE LIABLE TO THE USER FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY,
                            PUNITIVE, INDIRECT, OR CONSEQUENTIAL DAMAGES OF ANY KIND, UNDER ANY LEGAL
                            THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO
                            USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR
                            OTHER SUCH WEBSITES, OR ANY OTHER SERVICES OR ITEMS OBTAINED THROUGH THE
                            WEBSITE, OR OTHER SUCH WEBSITES, INCLUDING, BUT NOT LIMITED TO, LOST
                            REVENUE, LOST PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF
                            USE, LOSS OF GOODWILL, LOSS OF DATA, LOSS OF YOUR CREDENTIAL INFORMATION,
                            LOSS OR INTERRUPTION OF TECHNOLOGY, LOSS OF USE OF SERVICE OR EQUIPMENT,
                            EVEN IF THE USER WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND
                            WHETHER ARISING UNDER A THEORY OF CONTRACT, TORT, STRICT LIABILITY OR
                            OTHERWISE. WE WILL ALSO HAVE NO LIABILITY FOR ANY EMERGENCY AND/OR
                            UNFORESEEABLE INCIDENTS RELATED TO YOUR USE OF THE SERVICES ON THE
                            GAMEZONE PLATFORM SUCH AS STOLEN PRIVATE KEY, OR HACKED ACCOUNTS.
                        </strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            10.2 Except as expressly provided in these Terms, and to the maximum
                            extent permitted by any Applicable Laws, we disclaim all other
                            representations or warranties, express or implied, made to you, your
                            affiliates, or any other person, including, without limitation, any
                            warranties regarding the quality, suitability, merchantability, fitness
                            for a particular purpose or otherwise (regardless of any course of
                            dealing, custom or usage of trade) of any service provided incidental to
                            the Services under these Terms.
                        </strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            10.3 In no event will our aggregate liability for any loss or damage that
                            arises in connection with the Services exceed the purchase amount you paid
                            to participate in the Service, if any, during a twelve (12) month period
                            immediately preceding the event that gave rise to the claim for liability.
                            The preceding limitations of liability will apply to the fullest actual
                            amount you paid to participate in or access in the Service.
                        </strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            10.4 Except as expressly provided in these Terms, and to the fullest
                            extent permitted by any Applicable Laws, us, our Affiliate, and our
                            related parties each disclaim all liability to you for any loss or damage
                            arising out of or due to:
                        </strong>
                    </p>
                    <p style={{ paddingLeft: 80 }}>
                        10.4.1 your use of, inability to use, or availability or unavailability of
                        the Services, including any Third Party Services made available through the
                        Services;
                    </p>
                    <p style={{ paddingLeft: 80 }}>
                        10.4.2 the occurrence or existence of any defect, interruption, deletion of
                        files, delays in the operation or transmission of information to, from, or
                        through the Services, communications failure, theft, destruction or
                        unauthorised access to our records, programs, services, server, or other
                        infrastructure relating to the Services;
                    </p>
                    <p style={{ paddingLeft: 80 }}>
                        10.4.3 the Services being infected with any malicious code or viruses; or
                    </p>
                    <p style={{ paddingLeft: 80 }}>
                        10.4.4 the failure of the Services to remain operational for any period of
                        time.
                    </p>
                </div>

                <div className='mb-4'>
                    <h3>11. Indemnification</h3>
                    You irrevocably undertake the responsibility of fully indemnifying and holding
                    harmless each of us, our Partners, our Affiliate, licensors, shareholders,
                    officers, directors, managers, employees, and agents from and against any and
                    all losses, claims, actions, proceedings, damages, demands, judgements, sums,
                    liabilities, damages, costs, charges and expenses, including, but not limited
                    to, any reasonable attorney's fees or penalties imposed by any regulatory
                    authority, and reimbursements arising out of or related to the following
                    situations:
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            11.1 Your use or any person using the Services on your behalf or
                            participation in accordance with the Services on the Website or the
                            GAMEZONE Platform;
                        </strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>11.2 Your breach of or our enforcement of these Terms;</strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            11.3 Any violations of Applicable Laws, regulation, or rights of any
                            third-party during your use or participation in the Service.
                        </strong>
                    </p>
                    <p>If you are obligated to indemnify us, our Affiliate, shareholders, licensors,
                        officers, directors, managers, employees, and agents, we will have the right,
                        at our sole discretion, to control any action or proceeding and to determine
                        whether we wish to proceed, or settle, and if so, on what terms or provisions.</p>
                </div>

                <div className='mb-4'>
                    <h3>12. Termination</h3>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            12.1 These Terms will be immediately terminated by discontinuing your use
                            of or participating in the Services and you agree to terminate the
                            accessibility on the GAMEZONE Platform.
                        </strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            12.2 These Terms can be suspended or terminated without a notice from us
                            if there is a reasonable ground for us to believe that you have breached
                            any of the terms or provisions stipulated in these Terms, or if you do not
                            comply with these Terms.
                        </strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            12.3 The termination of these Terms will not prevent us from seeking
                            remedies from you in the case where you have breached any terms or
                            provisions of these Terms before such termination. We will not be liable
                            to you or to any third party for any termination, suspension, or
                            modification of your access to the Services.
                        </strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            12.4 Any ongoing obligation to you as well as the provisions regarding (i)
                            Intellectual Property, (ii) Indemnification, (iii) Limitation of
                            Liability, and (iv) any other provisions designed to survive, will survive
                            any termination or expiration of these Terms for any reason.
                        </strong>
                    </p>
                </div>

                <div className='mb-4'>
                    <h3>13. No Financial and Legal Advice</h3>
                    <p>We are merely a technology platform, and are not your broker, intermediary,
                        agent, or legal advisor and have no fiduciary relationship or obligation to
                        you in connection with any decisions or activities affected by you using the
                        Website or the Services. No communication or information provided to you by us
                        is intended as or will be considered or construed as, the solicitation of an
                        offer to buy, the investment advice, financial advice, legal advice, or any
                        other sort of advice. All Services, transactions, and Investments will be
                        executed automatically based on the parameters of your consideration. You will
                        be solely responsible for determining whether any Services, or investments are
                        suitable and match your interests according to your judgement, objectives,
                        circumstances, and risk tolerance. You will be solely responsible for any
                        losses or liabilities therefrom. Before executing any transactions, purchasing
                        GAMEZONE Token, Project’s token, in-game assets and NFTs on the GAMEZONE
                        Platform, you should consult with your independent financial, legal, or tax
                        professionals. We will not be liable for the decisions you make to access and
                        purchase through us.</p>
                </div>

                <div className='mb-4'>
                    <h3>14. Notice/Announcement</h3>
                    <p>Any notice, requests, demands, and determinations for us under these Terms
                        (other than routine operational communications) shall be sent to&nbsp;
                        <strong>support@gamezone.io</strong>&nbsp;at Website.
                    </p>
                </div>

                <div className='mb-4'>
                    <h3>
                        15. Governing Law, Resolving Disputes, Arbitration and Class Action Waiver
                    </h3>
                    PLEASE READ THIS SECTION CAREFULLY AS IT INVOLVES A WAIVER OF CERTAIN RIGHTS
                    TO BRING LEGAL PROCEEDINGS, INCLUDING A CLASS ACTION.
                    <p style={{ paddingLeft: 40 }}>
                        <strong>15.1 Notice of Claim and Dispute Resolution Period</strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        Please contact us first. We will seek to address your concerns without
                        resorting to formal legal proceedings whenever possible. If you have a
                        dispute with the Company, you should contact us, and a case number will be
                        assigned. We will attempt to resolve your dispute internally as soon as
                        possible. The Parties will agree to negotiate in good faith to resolve the
                        dispute and discussions will remain confidential and subject to applicable
                        laws protecting settlement discussions from use as evidence in any legal
                        proceeding.
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>15.2 Agreement to Arbitrate</strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        Parties agree that subject to Clause 15.1 above, any dispute, claim, or
                        controversy between Parties that arises in connection with, or relating in
                        any way, to these Terms, or to your relationship with us as an user of the
                        Services (whether by contract, tort, statute, fraud, misrepresentation, or
                        any other legal theory, and whether the claims arise during or after the
                        termination of these Terms) will be determined by a mandatory final and
                        binding individual arbitration rather than a class action, except as set
                        forth below under Exceptions to the Agreement to Arbitrate. The Parties
                        further agree that the arbitrator will have the exclusive power to rule on
                        his or her jurisdiction, including, without limitation, any objections with
                        respect to the existence, scope, or validity of the Agreement to Arbitrate,
                        or to the arbitrability of any claim or counterclaim. Arbitration is more
                        informal than a lawsuit in court. THERE IS NO JUDGE OR JURY IN ARBITRATION,
                        AND COURT REVIEW OF AN ARBITRATION AWARD IS LIMITED. There may be more
                        limited discovery than in court. The arbitrator must follow this agreement
                        and can award the same damages and relief as a court, including, if
                        applicable, attorney fees, except the arbitrator may not award declaratory
                        or injunctive relief benefiting anyone but the parties to the arbitration.
                        The arbitration provisions set forth in Clause 15 will survive termination
                        of these Terms.
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>15.3 Arbitration Rules</strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        &nbsp;Any dispute arising out of or in connection with these Terms including
                        any question regarding its existence, validity or termination, shall be
                        referred to and finally resolved by arbitration administered by the
                        Singapore International Arbitration Centre (“SIAC”) in accordance with the
                        Arbitration Rules of the Singapore International Arbitration Centre ("SIAC
                        Rules") for the time being in force, which rules are deemed to be
                        incorporated by reference in these Terms. The seat of the arbitration shall
                        be Singapore. The Tribunal shall consist of one arbitrator. The language of
                        the arbitration shall be English.
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>15.4 Governing Law/Jurisdiction</strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        &nbsp;The governing law of the arbitration will be that of the Republic of
                        Seychelles.
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>&nbsp;15.5 Confidentiality&nbsp;</strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        The Parties agree that the arbitration will be kept confidential. The
                        existence of the arbitration, any non-public information provided in the
                        arbitration, and any submissions, orders or awards made in the arbitration
                        will not be disclosed to any non-parties except the tribunal, the parties,
                        their counsel, experts, witnesses, accountants and auditors, insurers and
                        reinsurers, and any other person necessary to facilitate the arbitration.
                        Notwithstanding the preceding, a Party may disclose information to the
                        extent that disclosure may be required to fulfil a legal duty, protect, or
                        pursue a legal right, or enforce or challenge an award in bona fide legal
                        proceedings. This confidentiality provision will survive the termination of
                        these Terms and any arbitration brought under these Terms.
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>15.6 Class Action Waiver</strong>
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        The Parties agree that any claims relevant to these Terms, or your
                        relationship with us will be brought against the other party in arbitration
                        on an individual basis only and not as a plaintiff or class member in a
                        purported class or representative action. The Parties further agree to waive
                        any right for such claims to be brought, heard, or arbitrated as a class,
                        collective, representative, or private attorney general action, to the
                        extent permissible by Applicable Laws. Combining or consolidating individual
                        arbitrations into a single arbitration is not permitted without the consent
                        of all parties involved.
                    </p>
                    <p style={{ paddingLeft: 40 }}>
                        <strong>
                            15.7 We reserve the right to update, modify, revise, suspend, or make
                            future changes to Clause 15.2 regarding the Parties' Agreement to
                            Arbitrate, subject to applicable laws. You hereby consent and agree that
                            it is your responsibility to ensure that your understanding of this Clause
                            is up to date. Subject to applicable laws, your continued use of your
                            GAMEZONE Platform’s account will be interpreted as your acceptance of any
                            modifications to Clause 15 regarding the Parties' Agreement to Arbitrate.
                            You agree that if you object to the modifications to Clause 15, we may
                            block access to your account pending closure of your account. In such
                            circumstances, these Terms prior to modification will remain in full force
                            and affect the pending closure of your accessibility.
                        </strong>
                    </p>
                </div>
                <h3>16. Miscellaneous</h3>
                <p style={{ paddingLeft: 40 }}>
                    <strong>16.1&nbsp;Severability</strong>
                </p>
                <p style={{ paddingLeft: 40 }}>
                    If any of the provisions in these Terms is found by a court of competent
                    authority to be invalid, void, unlawful or unenforceable under any
                    Applicable Laws, such unenforceability or invalidity will not render these
                    Terms unenforceable or invalid as a whole, and such provisions will be
                    deleted without affecting the remaining provisions herein.
                </p>
                <p style={{ paddingLeft: 40 }}>
                    <strong>16.2 Variation of Terms</strong>
                </p>
                <p style={{ paddingLeft: 40 }}>
                    We have the right to revise these Terms at our sole discretion at any time,
                    and by using the Website or other GAMEZONE’s platforms, you will be expected
                    to review such Terms regularly to ensure that you understand all provisions
                    stipulated in these Terms.
                </p>
                <p style={{ paddingLeft: 40 }}>
                    <strong>16.3 Assignment</strong>
                </p>
                <p style={{ paddingLeft: 40 }}>
                    We will be allowed to assign, transfer, and subcontract our rights and/or
                    obligations under these Terms without the need to provide you any
                    notification or acquire your consent. Nevertheless, you will not be
                    permitted to assign, transfer, or subcontract any of your rights and/or
                    obligations under these Terms.
                </p>
                <p style={{ paddingLeft: 40 }}>
                    <strong>16.4 Entire Agreement</strong>
                </p>
                <p style={{ paddingLeft: 40 }}>
                    These Terms, including the Privacy Policy and any rules contained on the
                    Website or other GAMEZONE’s platforms, constitute the sole and entire
                    agreement between Parties with respect to your use of the GAMEZONE Platform,
                    and supersedes other prior or contemporaneous negotiations, discussions,
                    agreements, understandings, representations, and warranties, both written
                    and oral, between Parties with respect to such subject matter.
                </p>
                <p style={{ paddingLeft: 40 }}>
                    <strong>16.5 No Third-Party Rights</strong>
                </p>
                <p style={{ paddingLeft: 40 }}>
                    Nothing in these Terms will be deemed to create any rights to any creditors
                    or other persons, not a party hereto. Moreover, these Terms will not be
                    construed, in any respect, to be a contract, in whole or in part, for the
                    benefit of any third parties.
                </p>
                <p style={{ paddingLeft: 40 }}>
                    <strong>16.6 Clickwrap</strong>
                </p>
                <p style={{ paddingLeft: 40 }}>
                    We may deliver the Services through electronic means such as download links,
                    graphical, Tools or other technologies for providing the Service for users.
                    The user interfaces to such electronic means may require that the users
                    agree to these Terms by checking a box, clicking a button, or continuing
                    with the Services, the user complying through such actions shall become a
                    Party to these Terms. Such an action of acceptance shall be sufficient to
                    bind the users to the terms and conditions herein these Terms.
                </p>
                <p style={{ paddingLeft: 40 }}>
                    <strong>16.7 Waiver</strong>
                </p>
                <p style={{ paddingLeft: 40 }}>
                    The failure of one Party to require the performance of any provision will
                    not affect that Party's right to require performance at any time thereafter.
                    At the same time, the waiver of one Party to seek recovery for the other
                    Party's violation of these Terms of any provisions of applicable terms will
                    not constitute a waiver by that Party of any subsequent breach or violation
                    by the other Party or of the provision itself.
                </p>
                &nbsp;
            </>

        </Container>
    </div>
}
export default TermOfUsePage;