import React from 'react';
import { Table } from "react-bootstrap";
import GZCard from '../../../shared/components/card';

const TokenInformationComponent = ({ selectedProject }) => {
    return <GZCard title="Token information" className='mb-5'>
        <Table responsive className="mb-0">
            <tbody>
                <tr>
                    <td>Name</td>
                    <td className="text-end">
                        <h5 className="mb-0">{selectedProject.name}</h5>
                    </td>
                </tr>
                <tr>
                    <td>Token Symbol</td>
                    <td className="text-end">
                        <h5 className="mb-0">{selectedProject.projectTokenSymbol}</h5>
                    </td>
                </tr>
            </tbody>
        </Table>
    </GZCard>
}
export default TokenInformationComponent