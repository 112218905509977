import { BigNumber } from "bignumber.js";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { useActiveWeb3React } from "../../hook";
import GZCard from "../../shared/components/card";
import { useBnbBalance, useIsConnectWallet, useStakingInfo, useStakingWalletInfo, useWeb3Utils } from "../../shared/components/hook/useSate";
import StakeInfo from "../../shared/components/StakeInfo";
import { ACTION_CONST, ROUTES, STATUS } from "../../shared/constants";
import { helpers } from "../../shared/utils";
import { BSC_EXPLORER } from "../../_configs";

const UnStakingPage = () => {
    const dispatch = useDispatch();
    const [unStakeCurrentStep, setUnStakeCurrentStep] = useState(1);
    const isConnectWallet = useIsConnectWallet()
    const walletUtils = useWeb3Utils();
    const stakingInfo = useStakingInfo();
    const stakingWalletInfo = useStakingWalletInfo();
    const bnbBalance = useBnbBalance()
    const [acceptTerm, setAcceptTerm] = useState(false);
    const [enableUnStakeBtn, setEnableUnStakeBtn] = useState(false);
    const [stakedDuration, setStakedDuration] = useState(0);
    const [totalPctFee, setTotalPctFee] = useState(0);
    const [transactionHash, setTransactionHash] = useState("");
    const [unStakeAmount, setUnStakeAmount] = useState('0');
    const [isMaxAmount, setIsMaxAmount] = useState(false);
    const [isSubmitOK, setIsSubmitOK] = useState(false);
    const {  chainId } = useActiveWeb3React();
    useEffect(() => {
        if (unStakeCurrentStep === 2) {
            if (acceptTerm &&
                isConnectWallet &&
                !stakingInfo["isStakingPaused"] &&
                parseFloat(stakingWalletInfo["stakedAmount"]) > 0 &&
                bnbBalance > 0 &&
                stakingWalletInfo["withdrawTimestamp"] === 0
            ) {
                setEnableUnStakeBtn(true);
            } else {
                setEnableUnStakeBtn(false);
            }
        }
        if (unStakeCurrentStep === 1) setEnableUnStakeBtn(true);
        if (stakingInfo && stakingWalletInfo["stakedDuration"]) {
            setStakedDuration(stakingWalletInfo["stakedDuration"]);
            setTotalPctFee(stakingWalletInfo["totalPctFee"] / 100);
        }
    }, [acceptTerm, isConnectWallet, bnbBalance, stakingInfo, stakingWalletInfo, unStakeCurrentStep])


    const unStakeBack = () => {
        if (unStakeCurrentStep === 1) {
            return;
        } else {
            setUnStakeCurrentStep(unStakeCurrentStep - 1);
        }
    }

    const unStakeNext = () => {
        if (unStakeCurrentStep === 1) {
            setEnableUnStakeBtn(false);
            setUnStakeCurrentStep(unStakeCurrentStep + 1);
            return;
        }
        if (unStakeCurrentStep === 2 && parseFloat(unStakeAmount) === 0) {
            setEnableUnStakeBtn(false);
            setUnStakeCurrentStep(unStakeCurrentStep + 1);
            return;
        }
        if (unStakeCurrentStep === 4) {
            if (walletUtils) {
                dispatch({
                    type: ACTION_CONST.REQUEST_SUBMIT
                })
                walletUtils.stakingInitiateWithdrawal({ amount: isMaxAmount ? stakingWalletInfo["stakedAmount"] : unStakeAmount }, data => {
                    if (data.status === STATUS.STAKING_INITIATE_WITHDRAWAL_SUCCESS) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })
                        setIsSubmitOK(true);
                        setTransactionHash(data.txID);
                        setUnStakeCurrentStep(5);
                    }
                    if (data.status === STATUS.STAKING_INITIATE_WITHDRAWAL_FAIL) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })
                        toast.error("INIT Unstake fails");
                    }
                })
            }
        } else {
            if (unStakeCurrentStep === 5) {
                return;
            } else {
                setUnStakeCurrentStep(unStakeCurrentStep + 1);
            }
        }
    }

    const handleInputUnStake = (e) => {
        setIsMaxAmount(false);
        if (helpers.isFloatFormatted(e.target.value, 4)) {
            setUnStakeAmount(e.target.value);
            if (BigNumber(e.target.value).lte(BigNumber(stakingWalletInfo["stakedAmount"])) && BigNumber(e.target.value).gt(BigNumber(0))) {
                setEnableUnStakeBtn(true);
            } else {
                setEnableUnStakeBtn(false);
            }
        }
    }

    const handleMaxButtonClick = () => {
        setUnStakeAmount(helpers.formatNumberDownRound(stakingWalletInfo["stakedAmount"], 4));
        setEnableUnStakeBtn(true);
        setIsMaxAmount(true);
    }

    const submitDone = () => {
        setUnStakeAmount('0');
        setIsMaxAmount(true);
        setUnStakeCurrentStep(1);
        setIsSubmitOK(false);
    }

    return (
        <div className="gz-stake-page">
            <Container>
                <StakeInfo />
                <div className="gz-page-title">Unstake & Withdraw your $GZONE</div>
                <div className="gz-stepper">
                    <div className={`gz-stepper-item ${unStakeCurrentStep >= 1 ? 'active' : ''}`}>
                        <span>Warning</span>
                    </div>
                    <div className={`gz-stepper-item ${unStakeCurrentStep >= 2 ? 'active' : ''}`}>
                        <span>Checkpoints</span>
                    </div>
                    <div className={`gz-stepper-item ${unStakeCurrentStep >= 3 ? 'active' : ''}`}>
                        <span>Enter Amount</span>
                    </div>
                    <div className={`gz-stepper-item ${unStakeCurrentStep >= 4 ? 'active' : ''}`}>
                        <span>Initialize Unstake</span>
                    </div>
                    <div className={`gz-stepper-item ${unStakeCurrentStep >= 5 ? 'active' : ''}`}>
                        <span>Confirmation</span>
                    </div>
                </div>
                <div className="gz-st-body">
                    {
                        unStakeCurrentStep === 1 &&
                        <div className="gz-st-step" id="UnStakeStep1">
                            <div className="gz-st-step-title">Warning</div>
                            <div className="gz-st-step-description"><i className="fas fa-exclamation-triangle text-warning me-3"></i> You may be subject to a fee if you wish to unstake &amp; withdraw your $GZONE tokens early.</div>
                            <div className="gz-st-step-body">
                                <div className="mb-2">{'You have staked for'} [{helpers.convertSecondsToReadableString(stakedDuration)}]. Your current fee is: <b className="text-white">{totalPctFee}%</b></div>
                                <div className="mb-4">Fees will be deducted at the Withdraw stage. Please click 'Next' if you wish to proceed.</div>
                                <Row className="justify-content-center">
                                    <Col lg={6} md={8}>
                                        <GZCard className="py-0">
                                            <Table responsive className="mb-0">
                                                <tbody>
                                                    <tr>
                                                        <td>Staked less than 2 weeks</td>
                                                        <td className="text-end">
                                                            <h5 className="mb-0">25.00%</h5>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{'Less than 4 weeks'}</td>
                                                        <td className="text-end">
                                                            <h5 className="mb-0">15.00%</h5>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{'Less than 6 weeks'}</td>
                                                        <td className="text-end">
                                                            <h5 className="mb-0">10.00%</h5>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{'Less than 8 weeks'}</td>
                                                        <td className="text-end">
                                                            <h5 className="mb-0">5.00%</h5>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: 0 }}>{'8 weeks or more'}</td>
                                                        <td style={{ border: 0 }} className="text-end">
                                                            <h5 className="mb-0">0.00%</h5>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </GZCard>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    }

                    {
                        unStakeCurrentStep === 2 &&
                        <div className="gz-st-step" id="UnStakeStep2">
                            <div className="gz-st-step-title">Checkpoints</div>
                            <div className="gz-st-step-description">The following conditions must be met to proceed</div>
                            <div className="gz-st-step-body">
                                <GZCard className="pt-3">
                                    <Row className="gx-xl-5">
                                        <Col xl={3} md={6} className="mb-xl-0 mb-5">
                                            <div className={`gz-st-step-item ${isConnectWallet ? 'selected' : ''}`} >
                                                <div className="gz-st-step-item-num">01</div>
                                                <div className="gz-st-step-item-title">Connected MetaMask</div>
                                                <div className="gz-st-step-item-body">
                                                    If not connected, click the "Connect Wallet" button in the top right corner
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={3} md={6} className="mb-xl-0 mb-5">
                                            <div className={`gz-st-step-item ${parseFloat(stakingWalletInfo["stakedAmount"]) > 0 ? 'selected' : ''}`} >
                                                <div className="gz-st-step-item-num">02</div>
                                                <div className="gz-st-step-item-title">Have an active $GZONE stake</div>
                                                <div className="gz-st-step-item-body">
                                                    You currently have {helpers.formatNumberDownRound(stakingWalletInfo["stakedAmount"], 2)} $GZONE staked
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={3} md={6} className="mb-xl-0 mb-5">
                                            <div className={`gz-st-step-item ${bnbBalance > 0 ? 'selected' : ''}`} >
                                                <div className="gz-st-step-item-num">03</div>
                                                <div className="gz-st-step-item-title">BNB available</div>
                                                <div className="gz-st-step-item-body">
                                                    BNB is required to pay transaction fees on the Binance Smart Chain network.<br />
                                                    BNB Balance: {helpers.formatNumberDownRoundWithExtractMax(bnbBalance, 4)}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={3} md={6} className="mb-xl-0 mb-5">
                                            <div className={`gz-st-step-item last ${stakingWalletInfo["withdrawTimestamp"] === 0 ? 'selected' : ''}`} >
                                                <div className="gz-st-step-item-num">04</div>
                                                <div className="gz-st-step-item-title">Eligible to stake</div>
                                                <div className="gz-st-step-item-body">
                                                    You cannot stake if you have an active $GZONE unstake/withdrawal request
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <div className="pt-5">
                                        <div className="gz-form-check form-check">
                                            <input className="form-check-input float-none me-1"
                                                type="checkbox" defaultValue id="flexCheckDefault"
                                                onChange={() => setAcceptTerm(!acceptTerm)} />
                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                I have read the&nbsp;
                                                <Link target="_blank" to={ROUTES.TERM_OF_USES}>Terms and Conditions </Link>
                                            </label>
                                        </div>
                                    </div>
                                </GZCard>
                            </div>
                        </div>
                    }

                    {
                        unStakeCurrentStep === 3 &&
                        <div className="gz-st-step" id="UnStakeStep3">
                            <div className="gz-st-step-title">Amount to <br />Unstake &amp; Withdraw</div>
                            <div className="gz-st-step-description">Please enter the amount of $GZONE you want to unstake</div>
                            <div className="gz-st-step-note">Any fees applied at Unstake &amp; Withdraw stage will be based on the date you last staked.</div>
                            <div className="gz-st-step-body">
                                <div className="mx-auto" style={{ maxWidth: '92%', width: 500 }}>
                                    <GZCard className="pt-3 text-start">
                                        <div className="gx-form-label">Amount</div>
                                        <div className="gz-input-group">
                                            <input type="number" placeholder={0.0} value={unStakeAmount} onChange={(e) => handleInputUnStake(e)} />
                                            <span style={{ cursor: 'pointer' }} onClick={handleMaxButtonClick}>MAX</span>
                                        </div>
                                    </GZCard>
                                </div>
                            </div>
                        </div>
                    }

                    {
                        unStakeCurrentStep === 4 &&
                        <div className="gz-st-step" id="UnStakeStep4">
                            <div className="gz-st-step-title">Confirm <br />Unstaking Process</div>
                            <div className="gz-st-step-description">In this step, you initiate the unstaking process.</div>
                        </div>
                    }

                    {
                        unStakeCurrentStep === 5 &&
                        <div className="gz-st-step" id="UnStakeStep5">
                            <div className="gz-st-step-title">Confirmed</div>
                            <div className="gz-st-step-description">
                                You have initiated the unstaking process.<br />
                                If desired, you may check Binance SmartChain to confirm the transaction.
                            </div>
                            <div className="gz-st-step-body">
                                <a className="gz-address" href={`${BSC_EXPLORER[chainId]}/tx/${transactionHash}`} target="blank">{transactionHash}</a>
                            </div>
                        </div>
                    }
                </div>
            </Container>

            <div className="gz-st-step-actions my-5 pb-5 d-flex justify-content-center" style={{ columnGap: '10px' }}>
                {!isSubmitOK ?
                    <>
                        <Button onClick={() => unStakeBack()} disabled={!enableUnStakeBtn || unStakeCurrentStep === 1}>
                            <span><i className="mdi mdi-arrow-left me-2" />Previous</span>
                        </Button>
                        <Button onClick={() => unStakeNext()} disabled={!enableUnStakeBtn || unStakeCurrentStep === 5}>
                            <span>Next<i className="mdi mdi-arrow-right ms-2" /></span>
                        </Button>
                    </>
                    : <Button onClick={() => submitDone()}><span>Done</span></Button>
                }
            </div>
        </div>
    );
};

export default UnStakingPage;
