import $ from 'jquery';
import React, { useEffect, useState } from "react";
import "react-block-ui/style.css";
import { Button, Col, Container, Image, ProgressBar, Row } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { ACTION_CONST, ROUTES } from "../../constants";
import { useActiveWeb3React } from "../../hook";
import { useBlockingUI, useInfoRound, useLatestBlockNumber, useSelectedProject, useWeb3Utils } from "../../hook/useState";
import { actGetListProjects, actSelectedProject, actSetCurrentContract } from "../../redux/action/user";
import GZCard from '../../shared/components/card';
import ConnectWalletModal from '../../shared/modals/ConnectWalletModal';
import { getCountDown } from "../../shared/utils/helper";
import { helpers } from "../../shared/utils/helpers";
import { history } from "../../shared/utils/history";
import PoolInformationComponent from './components/PoolInformationComponent';
import ScheduleTabComponent from "./components/ScheduleTabComponent";
import TokenInformationComponent from './components/TokenInformationComponent';
import YourAllocationComponent from "./components/YourAllocationComponent";
import ApproveModal from "../../shared/modals/ApproveModal";
import InvestmentModal from "../../shared/modals/InvestModal";

const ProjectDetailPage = () => {
  const dispatch = useDispatch();
  const { account } = useActiveWeb3React();
  const showBlockUI = useBlockingUI()
  const latestBlock = useLatestBlockNumber();
  const selectedProject = useSelectedProject();
  const web3Utils = useWeb3Utils();
  const roundInfo = useInfoRound();
  const [closeTime, setCloseTime] = useState(0);
  const [openTime, setOpenTime] = useState(0)
  const [fcfsOpenTime, setFcfsOpenTime] = useState(0)
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [contractAddress, setContractAddress] = useState("");
  const [tokenBalance, setTokenBalance] = useState(0);
  const [ethBalance, setEthBalance] = useState(0);
  const [remainingAllocation, setMaxTokenAllocation] = useState(0);
  const [userParticipation, setUserParticipation] = useState(0);
  const [amountPurchased, setAmountPurchased] = useState(0);
  const [countBuy, setCountBuy] = useState(0);
  const [enableJoinProject, setEnableJoinProject] = useState(false);
  const [projectState, setProjectState] = useState(null);
  const [tier, setTier] = useState("");
  const [roundState, setRoundState] = useState(0);
  const [textRoundState, setTextRoundState] = useState('')
  const [roundTime, setRoundTime] = useState(0);
  const [allocationNumber, setAllocationNumber] = useState(0);
  const [allocationInfo, setAllocationInfo] = useState([])
  const [layoutAllocation, setLayoutAllocation] = useState(1)
  const [yourAllocationVisible, setYourAllocationVisible] = useState(false)
  const [enableApprove, setEnableApprove] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showJoinModal, setShowJoinModal] = useState(false);
  const params = useParams();

  const [walletInfo, setWalletInfo] = useState({
    remainingAllocation: 0,
    tokenBalance: 0,
  })

  useEffect(() => {
    $('body').addClass('no-bg');
    return (() => {
      $('body').removeClass('no-bg');
    })
  }, [])

  useEffect(() => {
    const { contract } = params
    if (contract && typeof contract === "string") {
      setContractAddress(contract);
      dispatch(actSelectedProject(contract));
      dispatch(actSetCurrentContract(contract));
    } else {
      history.push(ROUTES.HOMEPAGE);
      return;
    }
  }, [showBlockUI])

  useEffect(() => {
    if (contractAddress) {
      dispatch(actSelectedProject(contractAddress))
    }
  }, [latestBlock, contractAddress])

  useEffect(() => {
    if (selectedProject) {
      if (selectedProject.closeTime !== closeTime) {
        setCloseTime(selectedProject.closeTime);
      }
      if (selectedProject.openTime != openTime) {
        setOpenTime(selectedProject.openTime)
      }
      if (selectedProject.fcfsOpenTime != fcfsOpenTime) {
        setFcfsOpenTime(selectedProject.fcfsOpenTime)
      }
      setYourAllocationVisible(selectedProject.yourAllocationVisible)
      setProjectState(selectedProject)
    }
  }, [selectedProject]);

  useEffect(() => {
    if (openTime > 0) {
      getCountDown(`idOpenTime-${selectedProject["contract"]}`, openTime * 1000, (job) => {
        dispatch({
          type: ACTION_CONST.SET_JOB_COUNT_DOWN_OPEN,
          data: job
        })
      }, (job) => {
        getWalletInfo(contractAddress);
        dispatch(actSelectedProject(contractAddress))
      });
    }
  }, [openTime])

  useEffect(() => {
    if (closeTime > 0) {
      getCountDown(`idTimeClose-${selectedProject["contract"]}`, closeTime * 1000, (job) => {
        dispatch({
          type: ACTION_CONST.SET_JOB_COUNT_DOWN_CLOSE,
          data: job
        })
      }, (job) => {
        getWalletInfo(contractAddress);
        dispatch(actSelectedProject(contractAddress))
      });
    }
  }, [closeTime])

  useEffect(() => {
    if (fcfsOpenTime > 0) {
      getCountDown(`idFcfsOpenTime-${selectedProject["contract"]}`, fcfsOpenTime * 1000, (job) => {
        dispatch({
          type: ACTION_CONST.SET_JOB_COUNT_DOWN_FCFS_TIME,
          data: job
        })
      }, (job) => {
        getWalletInfo(contractAddress);
        dispatch(actSelectedProject(contractAddress))
      });
    }
  }, [fcfsOpenTime])

  useEffect(() => {
    if (roundTime > 0 && selectedProject) {
      getCountDown(`idRoundTime-${selectedProject["contract"]}`, roundTime * 1000, function start(job) {
        dispatch({
          type: ACTION_CONST.SET_JOB_COUNT_DOWN_ROUND,
          data: job
        })
      }, function end(job) {
        getWalletInfo(contractAddress);
        dispatch(actSelectedProject(contractAddress))
      });
    }
  }, [roundTime, selectedProject])

  useEffect(() => {
    if (Number(remainingAllocation) > Number(allocationNumber)) {
      setEnableApprove(true)
    } else {
      setEnableApprove(false)
    }
  }, [allocationNumber, remainingAllocation])

  useEffect(() => {
    if (account && web3Utils && projectState) {
      dispatch(actGetListProjects())
      fetchData()
    }
    async function fetchData() {
      const { contract } = params
      if (contract) {
        await getWalletInfo(contract);
        await getAllowance();
        await getYourAllocation(contract)
      }
    }
  }, [account, web3Utils, countBuy, contractAddress, projectState, latestBlock]);


  // console.log("projectState==>", projectState);
  const getWalletInfo = async (contract) => {
    if (web3Utils) {
      web3Utils.getInfoWallet(contract).then(

        data => {
          // console.log("getInfoWallet==>", data);
          setWalletInfo({
            remainingAllocation: data.remainingAllocation / 10 ** projectState.decimal,
            tokenBalance: data.tokenBalance / 10 ** projectState.decimal,
            ethBalance: data.ethBalance
          })

          setEthBalance(data.ethBalance);
          setTokenBalance(data.tokenBalance / 10 ** projectState.decimal);

          setUserParticipation(data.userParticipation / 10 ** projectState.decimal);
          setMaxTokenAllocation(data.remainingAllocation / 10 ** projectState.decimal);
          setTier(data.tier);
          setRoundState(data.roundState);
          setTextRoundState(data.roundStateText)


          if (document.getElementById('idTextRoundState')) {
            document.getElementById('idTextRoundState').innerHTML = data.roundStateText;
          }



          if (roundTime != data.roundTimestamp) {
            setRoundTime(data.roundTimestamp);
          }

          if (!(data.roundState === 1 || data.roundState === 3)) {
            // console.log('call here state ')
            setEnableJoinProject(false);
            return;
          }


          if (projectState.state === "C" || projectState.state === "P" || projectState.address === 'TBA') {
            setEnableJoinProject(false)
            return;
          }
          else {
            if (selectedProject.isPrivate) {
              if (data.remainingAllocation === 0) {

                setEnableJoinProject(false)
                return;
              } else {
                setEnableJoinProject(true)
                return;
              }
            } else {
              setEnableJoinProject(true)
              setMaxTokenAllocation(projectState.maxSingleParticipationAllocated)
              // remainingAllocation = maxSingleParticipationAllocated
            }
          }

        }
      ).catch(err => {

        console.log(err);
      })
    }

  }


  const getAllowance = async () => {
    web3Utils.getAllowance(selectedProject.tokenAddress, selectedProject.contract).then(data => {
      setAllocationNumber(data)
    }).catch(err => {
      console.log(err);
    })
  }

  const getYourAllocation = (contract) => {
    web3Utils.getInfoAllocations(contract).then(data => {
      setLayoutAllocation(data.layout)
      setAllocationInfo(data.infoAllocation)
    }).catch(err => {
      console.log(err);
    })
  }

  return (
    <div className='gz-project-detail-page'>
      {selectedProject ?
        <>
          <Container>
            <h1 className="gz-pd-name">{selectedProject.name}</h1>
            <div className="gz-pd-description">{selectedProject.description}</div>
            <div className="gz-pd-socials">
              {
                selectedProject.pancakeswap &&
                (<a href={selectedProject.pancakeswap} target="_blank" rel="noopener noreferrer">
                  <img height="20" src="/images/pancakeswap-cake-logo.png" alt="" />
                </a>)
              }
              {
                selectedProject.website &&
                (<a href={selectedProject.website} target="_blank" rel="noopener noreferrer">
                  <i className="fas fa-globe-americas"></i>
                </a>)
              }
              {
                selectedProject.twitter &&
                (<a href={selectedProject.twitter} target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-twitter"></i>
                </a>)
              }
              {
                selectedProject.medium &&
                (<a href={selectedProject.medium} target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-medium-m"></i>
                </a>)
              }
              {
                selectedProject.telegram &&
                (<a href={selectedProject.telegram} target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-telegram-plane"></i>
                </a>)
              }
            </div>
          </Container>

          <div className='gz-pd-logos'>
            <div className='gz-pd-logo'>
              <Image className='ms-auto' src={selectedProject.logo} alt={selectedProject.name} />
            </div>
            <div className='gz-pd-logo active'>
              <Image src={selectedProject.logo} alt={selectedProject.name} />
            </div>
            <div className='gz-pd-logo'>
              <Image className='me-auto' src={selectedProject.logo} alt={selectedProject.name} />
            </div>
          </div>

          <Container>
            <GZCard className='pt-3 position-relative'>
              <Row className='align-items-end'>
                <Col lg={6} className="mb-5 mb-lg-0">
                  {(selectedProject.state !== "P" && account) && (
                    <div className="mb-4">
                      <div className='gz-pd-pool-label'>Your balance</div>
                      <div className='gz-pd-pool-value'>
                        <span id="idBusdBalance">{helpers.formatNumberDownRound(tokenBalance, 4)} {selectedProject.symbol}</span>
                        {selectedProject.symbol !== "BNB" &&
                          <div className="mt-2">
                            <span id="idKcsBalance">{helpers.formatNumberDownRound(ethBalance, 4)} BNB</span>
                          </div>
                        }
                      </div>

                      <div className='gz-pd-pool-label mt-3'>Your approved amount</div>
                      <div className='gz-pd-pool-value'>
                        <span className="text-white detail-value">{helpers.formatNumberDownRound(allocationNumber, 4)} {selectedProject.symbol}</span>
                      </div>

                      {tier && <>
                        <div className='gz-pd-pool-label mt-3'>Your tier</div>
                        <div className='gz-pd-pool-value'>
                          <span>{tier}</span>
                        </div>
                      </>}
                    </div>
                  )}

                  {(selectedProject.state === "P" && account) && (
                    <div className='mb-4'>
                      <div className='gz-pd-pool-label'>Your balance</div>
                      <div className='gz-pd-pool-value'>
                        {
                          selectedProject.openTime !== undefined && (
                            <>
                              <div><span id="idBusdBalance">{helpers.formatNumberDownRound(tokenBalance, 4) || 0}&nbsp;{selectedProject.symbol}</span></div>
                              {
                                selectedProject.state !== "P" &&
                                <div id="idBusdConvert" className='mb-3'>1 {selectedProject.symbol} = {selectedProject.rate} {selectedProject.projectTokenSymbol}</div>
                              }
                            </>
                          )
                        }
                        {selectedProject.symbol !== "BNB" && (<div><span id="idethBalance">{helpers.formatNumberDownRound(ethBalance, 4) || 0} BNB</span></div>)}
                      </div>
                    </div>
                  )}

                  {
                    !account ?
                      <Button className='mb-0' onClick={() => setShowConnectModal(true)}>
                        <span>Connect Wallet</span>
                      </Button>
                      :
                      <>
                        {selectedProject.state !== "P" && (
                          <div className="gz-pd-buttons d-flex flex-wrap" style={{ gap: '20px' }}>
                            <Button className='mb-0' disabled={!enableApprove} onClick={() => setShowApproveModal(true)}>
                              <span>Approve</span>
                            </Button>
                            <Button className='mb-0' disabled={!enableJoinProject || allocationNumber == 0} onClick={() => setShowJoinModal(true)}>
                              <span>Join Pool</span>
                            </Button>
                          </div>
                        )}
                      </>
                  }
                </Col>
                <Col lg={6}>
                  {selectedProject.state !== "P" &&
                    <>
                      <div className='gz-pd-status'>
                        {
                          (selectedProject.state == "O") &&
                          <>
                            {account ?
                              roundState !== 4 ?
                                <div className="gz-status closing text-warning lbl-time ">
                                  {textRoundState && <span id="idTextRoundState">{textRoundState}</span>}
                                  <span id={`idRoundTime-${selectedProject["contract"]}`}> 0d 0h 0m 0s</span>
                                </div> :
                                <div className="gz-status round-closed text-danger">
                                  <i className="mdi mdi-circle text-danger"></i> ROUND CLOSED
                                </div>
                              :
                              <div className="gz-status opening text-warning lbl-time">
                                First Come First Serve <u>opens</u> in:
                                <span id={`idFcfsOpenTime-${selectedProject["contract"]}`}> 0d 0h 0m 0s</span>
                              </div>
                            }
                          </>
                        }

                        {
                          selectedProject.state == "F" &&
                          <>
                            {account ?
                              roundState !== 4 ?
                                <div className="gz-status closing text-warning lbl-time">
                                  <span id="idTextRoundState"> {textRoundState}</span><br />
                                  <span id={`idRoundTime-${selectedProject["contract"]}`}> 0d 0h 0m 0s</span>
                                </div> :
                                <div className="gz-status round-closed text-danger">
                                  <i className="mdi mdi-circle text-danger"></i> ROUND CLOSED
                                </div>
                              :
                              <div className="gz-status closing text-warning lbl-time">
                                Closing in: <span id={`idTimeClose-${selectedProject["contract"]}`}> 0d 0h 0m 0s</span>
                              </div>
                            }
                          </>
                        }

                        {
                          selectedProject.state === "C" &&
                          <div className="gz-status closed text-danger">
                            <i className="mdi mdi-circle text-danger"></i> CLOSED
                          </div>
                        }
                      </div>

                      {
                        account && (<>
                          <div className='gz-pd-pool-label'>Swapped</div>
                          <div className='gz-pd-pool-value'>
                            <div><span id="idUseParticipation">{helpers.formatNumberDownRound(userParticipation, 4)} {selectedProject.symbol}</span></div>
                            <div className='mt-2'><span className="detail-value"> {`${helpers.formatNumberDownRound(userParticipation * selectedProject.rate, 4)} ${selectedProject.projectTokenSymbol}`}</span></div>
                          </div>

                          <div className='gz-pd-pool-label mt-3'>Remaining Allocation</div>
                          <div className='gz-pd-pool-value'>
                            <div><span id="idBusdMaxBuy">{helpers.formatNumberDownRound(remainingAllocation, 4)} {selectedProject.symbol}</span></div>
                          </div>
                        </>)
                      }

                      {selectedProject.state !== "O" ?
                        <>
                          <div className='gz-pd-pool-label mt-3'>Swap progress</div>
                          <div className="gz-progress">
                            <div className="gz-progress-percent">
                              <ProgressBar animated striped now={(selectedProject.totalFundParticipated / selectedProject.maxTotalParticipationAllocated || 0) * 100} />
                            </div>
                            <div className="gz-progress-label">
                              <span>{((selectedProject.totalFundParticipated / selectedProject.maxTotalParticipationAllocated || 0) * 100).toFixed(2)}%</span>
                              <span>{selectedProject.totalFundParticipated.toFixed(4)}/{selectedProject.maxTotalParticipationAllocated} {selectedProject.symbol}</span>
                            </div>
                          </div>
                        </>
                        :
                        <>
                          <div className='gz-pd-pool-label'>Allocation round</div>
                          <div className="gz-pd-pool-value">
                            <span>{selectedProject.totalCountUserParticipated} <small style={{ fontSize: '16px' }}>Participants</small></span>
                          </div>
                        </>
                      }
                    </>
                  }
                </Col>
              </Row>
            </GZCard>
          </Container>

          <Container className='mt-5'>
            <Row className="gx-xl-5">
              <Col lg={6} className="mb-5 mb-lg-0">
                <PoolInformationComponent selectedProject={selectedProject} />
              </Col>
              <Col lg={6}>
                <TokenInformationComponent selectedProject={selectedProject} />
                <ScheduleTabComponent roundInfo={roundInfo} />
              </Col>
            </Row>

            {yourAllocationVisible &&
              <YourAllocationComponent
                allocationInfo={allocationInfo}
                tokenSymbol={selectedProject.projectTokenSymbol}
                decimals={projectState.decimals}
                contractAddress={contractAddress}
                tokenAddress={projectState.projectTokenContract}
                handleBuyClick={() => setCountBuy(countBuy + 1)}
                claim={selectedProject.claim}
                layout={layoutAllocation}
              />
            }
          </Container>
        </>
        :
        <div className="text-center">Loading...</div>
      }

      {showJoinModal && (<InvestmentModal walletInfo={walletInfo}
        allocationNumber={allocationNumber}
        remainingAllocation={remainingAllocation} handleBuyClick={() => setCountBuy(countBuy + 1)}
        countClick={countBuy}
        tokenBalance={helpers.formatNumberDownRound(tokenBalance, 4)}
        symbol={selectedProject?.symbol}
        handleInputAmount={(n) => setAmountPurchased(n)}
        onDismiss={() => setShowJoinModal(false)} />
      )}

      {showApproveModal && (<ApproveModal
        remainingAllocation={remainingAllocation}
        walletInfo={walletInfo}
        handleBuyClick={() => setCountBuy(countBuy + 1)}
        handleInputAmount={(n) => setAmountPurchased(n)}
        tokenBalance={helpers.formatNumberDownRound(tokenBalance, 4)}
        onDismiss={() => setShowApproveModal(false)} />
      )}

      {showConnectModal && (<ConnectWalletModal onDismiss={() => setShowConnectModal(false)} />)}
    </div>
  );
};

export default ProjectDetailPage;
