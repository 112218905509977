import React from "react";
import { Col, Container, Image, ProgressBar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useComingSoonProjects } from "../../../hook/useState";
import { getCountDown, remainTime } from "../../../shared/utils/helper";

const WaitingProjectsComponent = () => {
  const waitingProjects = useComingSoonProjects()

  return (
    <>
      <section className={`gz-projects coming-soon ${waitingProjects.length > 0 ? '' : 'no-data'}`}>
        <Container>
          <div className="gz-projects-heading">Projects <br />Coming soon</div>
          {waitingProjects.length > 0 ?
            <Row className="gx-md-5 justify-content-xl-center">
              {
                waitingProjects.map((item, key) => {
                  getCountDown(`idOpenTime-${item["contract"]}-${item["openTime"]}`, item.openTime * 1000, function start(job) {
                  }, function end(job) {
                  })
                  return (
                    <Col xl={4} lg={6} md={6} className="text-center" key={key}>
                      <div className="gz-project-card gz-project-item mb-4 mt-xl-3">
                        <div className="gz-project-header">
                          {item.state === "P" &&
                            <>
                              {

                                (item.contract === 'TBA' || item.openTime === 0 || item.rate == 0) ?
                                  <span className="gz-status-opening">Opens in TBA<b></b></span>
                                  : <span className="gz-status-opening">Opens in <b id={`idOpenTime-${item["contract"]}-${item["openTime"]}`}>{remainTime(item.openTime * 1000)}</b></span>
                              }
                            </>
                          }
                          {item.contract !== 'TBA' ? <div>{item.symbol}</div> : <></>}
                        </div>
                        <div className="gz-project-body">
                        <Link to={(item.contract && item.contract !== 'TBA') ? `projects/${item.contract}` : `#`} className="text-decoration-none d-block" >
                          <div className="gz-project-logo">
                            <img src={item.logo} alt="" />
                            {
                              item.athMultiplier &&
                              <div className="pp-top-tag">
                                <div><small>ATH</small></div>
                                <div><b>{item.athMultiplier}x</b></div>
                              </div>
                            }
                          </div>
                         
                          <div className="gz-project-name">{item.name}</div>
                          </Link>
                        </div>
                        <div className="gz-project-detail">
                          <div className="gz-project-description">{item.description}</div>
                          {
                            item.button_name &&
                            <div className="text-center mb-4 mx-5">
                              <a href={item.button_url} target="blank" className="btn btn-primary btn-sm mx-auto" style={{ width: '80%' }}>
                                <span>{item.button_name}</span>
                              </a>
                            </div>
                          }

                          <ul className="gz-project-info">
                            <li>
                              <span>Swap rate</span><br />
                              <b className="nowrap text-white">
                                {!item.contract ? "TBA" :
                                  item.contract !== 'TBA' ?
                                    item.rate !== 0 ?
                                      (`1 ${item.symbol} = ${item["rate"]} ${item["projectTokenSymbol"]}`)
                                      : 'TBA'
                                    : "TBA"}

                              </b>
                            </li>
                            <li>
                              <Row>
                                <Col>
                                  <span>Cap</span><br />
                                  <b className="nowrap text-white">
                                    {
                                      !item.contract ? "TBA" :
                                        item.contract !== 'TBA' ?
                                          `${item.maxTotalParticipationAllocated || 0} ${item.symbol || ""}`
                                          : `TBA`
                                    }
                                  </b>
                                </Col>
                                <Col className="text-end">
                                  <span>Access</span><br />
                                  <b className="nowrap text-white">
                                    {item.isPrivate ? "Private" : "Public"}
                                  </b>
                                </Col>
                              </Row>
                            </li>
                          </ul>
                          <div className="gz-project-socials">
                            <div className="gz-project-socials-inner">
                              {
                                item.pancakeswap &&
                                <a href={item.pancakeswap} target="_blank" rel="noopener noreferrer">
                                  <Image height="18" src="/images/pancakeswap-cake-logo.png" alt="" />
                                </a>
                              }
                              {
                                item.website &&
                                <a href={item.website} target="_blank" rel="noopener noreferrer"><i className="fas fa-globe"></i></a>
                              }
                              {
                                item.twitter &&
                                <a href={item.twitter} target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
                              }
                              {
                                item.medium &&
                                <a href={item.medium} target="_blank" rel="noopener noreferrer"><i className="fab fa-medium-m"></i></a>
                              }
                              {
                                item.telegram &&
                                <a href={item.telegram} target="_blank" rel="noopener noreferrer"><i className="fab fa-telegram"></i></a>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  )
                })
              }
            </Row> :
            <div className="text-center">
              <div className="mb-3">No projects currently coming soon</div>
              <Image className="gz-no-data" alt='' src="/images/robot.png" style={{ width: '60vw', marginBottom: -150 }} />
            </div>
          }
        </Container>
      </section>
    </>
  );
};

export default WaitingProjectsComponent;
