import { get } from "lodash";
import React from 'react';
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import { useSelector } from 'react-redux';
import Footer from "../components/Footer";
import Header from "../components/Header";

export const PrivateLayout = ({ children, ...rest }) => {
    const showBlockUI = useSelector((state) =>
        get(state, "utils.blocking", false)
    );
    return (
        <>
            <BlockUi tag="div" blocking={showBlockUI}>
                <Header />
                {children}
                <Footer />
            </BlockUi>
        </>
    );
};