import exactMath from 'exact-math';
import React, { useEffect, useState } from "react";
import { Button, Container, Image, Nav, Navbar } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { useActiveWeb3React } from "../../hook";
import { useKycStatus, useLatestBlockNumber, useStakeAmount } from "../../hook/useState";
import { getKYC } from "../../redux/services/kyc.api";
import { helpers } from "../../shared/utils";
import { showKYC } from "../../_configs";
import { ACTION_CONST, ROUTES } from "../constants";
import ConnectWalletModal from '../modals/ConnectWalletModal';
import WalletModal from '../modals/WalletModal';
import Web3Helper from '../utils/walletExtensionUtils';
import { usePadTokenBalance, useWeb3Utils } from "./hook/useSate";

const Header = () => {
  const dispatch = useDispatch();
  const { account, library, error, chainId } = useActiveWeb3React()
  const walletUtils = useWeb3Utils()
  const bscpadBalance = usePadTokenBalance();
  const latestBlock = useLatestBlockNumber()
  const stakedAmount = useStakeAmount()
  const kycStatus = useKycStatus();
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [showWalletModal, setShowWalletModal] = useState(false);



  useEffect(() => {
    if (walletUtils && account) {
      walletUtils.web3.eth.getBalance(account).then(balance => {
        dispatch({
          type: ACTION_CONST.GET_BNB_BALANCE,
          data: exactMath.div(balance, 10 ** 18)
        })
      }).catch(err => {
        console.log(err);
      })

      walletUtils.getTokenPadBalance().then(data => {
        dispatch({
          type: ACTION_CONST.GET_GZONE_WALLET_BALANCE,
          data: data
        })
      })

      walletUtils.getStakingInfoWallet().then(data => {
        dispatch({ type: ACTION_CONST.GET_STAKING_WALLET_INFO, data: data })
      });
    }
  }, [latestBlock, walletUtils, account]);

  useEffect(() => {
    if (error)
      return;
    if (account && library && library.provider) {
      const web3 = new Web3Helper(library.provider, account, chainId)
      dispatch({
        type: ACTION_CONST.ENABLE_WALLET_SUCCESS,
        data: web3
      })
      return;
    }
  }, [library, account, error])

  useEffect(() => {
    if (!account) {
      return;
    }
    if ((Number(bscpadBalance) + Number(stakedAmount)) >= Number(showKYC)) {
      getKYCAddress(account)
    }
  }, [account, bscpadBalance, stakedAmount, latestBlock])

  const getKYCAddress = (address) => {
    getKYC(address, 'state').then(response => {
      address = address.toLowerCase()
      if (response) {
        const state = response.state;
        if (state === 1) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'START'
          })
        }
        if (state === 2) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'PENDING'
          })
        }
        if (state === 3) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'APPROVED'
          })
        }
        if (state === 4) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'ERROR'
          })
        }
      }

    }).catch(err => {
      console.log(err);
    })
  }

  const handleOnclickKyc = () => {
    getKYC(account, 'url').then(data => {
      if (data) {
        const url = data.url
        window.open(url, "_blank")
      }
    }).catch(err => {
      console.log(err);
    })
  }

  const [isSticky, setIsSticky] = useState('relative');

  useEffect(() => {
    stickNavbar();
    window.addEventListener('scroll', stickNavbar);

    return () => {
      window.removeEventListener('scroll', stickNavbar);
    };
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      setIsSticky(windowHeight > 50);
    }
  };

  return <>
    <Navbar fixed="top" variant="dark" expand="md" className={isSticky ? 'sticky-top' : ''}>
      <Container>
        <Navbar.Brand href="https://gamezone.io">
          <Image src="/images/logo.svg" height="46" alt="GAMEZONE" className="me-2" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavLink className="nav-link" activeClassName="active" to={ROUTES.PROJECTS}>Projects</NavLink>
            <NavLink className="nav-link" activeClassName="active" to={ROUTES.STACKING}>Stake</NavLink>
            <NavLink className="nav-link" activeClassName="active" to={ROUTES.UNSTACKING}>Unstake &amp; Withdraw</NavLink>
          </Nav>
          <Nav className="ms-auto">
            {
              !account ?
                <Nav.Item>
                  <Button onClick={() => setShowConnectModal(true)}>
                    <span>Connect Wallet</span>
                  </Button>
                </Nav.Item>
                :
                <>
                  <Nav.Item>
                    <Button onClick={() => setShowWalletModal(true)}>
                      <span>{helpers.formatTransactionHash(account, 4, 4)} - {helpers.formatNumberDownRoundWithExtractMax(bscpadBalance, 4)} $GZONE</span>
                    </Button>
                  </Nav.Item>
                  {
                    kycStatus === 'START' &&
                    <Nav.Item>
                      <Button className="kyc" variant="warning" onClick={() => handleOnclickKyc()}>
                        <i className='fas fa-bolt me-2' style={{ marginLeft: -8 }}></i>
                        <span >KYC</span>
                      </Button>
                    </Nav.Item>
                  }
                  {
                    kycStatus === 'PENDING' &&
                    <Nav.Item>
                      <Button className="kyc" variant="warning" onClick={() => handleOnclickKyc()}>
                        <i className='fas fa-exclamation-circle me-2' style={{ marginLeft: -8 }}></i>
                        <span>KYC</span>
                      </Button>
                    </Nav.Item>
                  }
                  {
                    kycStatus === 'APPROVED' &&
                    <Nav.Item>
                      <Button className="kyc" variant="success">
                        <i className='fas fa-check me-2' style={{ marginLeft: -8 }}></i>
                        <span>KYC</span>
                      </Button>
                    </Nav.Item>
                  }
                  {
                    kycStatus === 'ERROR' &&
                    <Nav.Item>
                      <Button className="kyc" variant="danger" onClick={() => handleOnclickKyc()}>
                        <i className='fas fa-exclamation-triangle me-2' style={{ marginLeft: -8 }}></i>
                        <span>KYC</span>
                      </Button>
                    </Nav.Item>
                  }
                </>
            }
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    {showConnectModal && (<ConnectWalletModal onDismiss={() => setShowConnectModal(false)} />)}
    {showWalletModal && (<WalletModal onDismiss={() => setShowWalletModal(false)} />)}

  </>;
};

export default Header;
