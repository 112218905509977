import React, { useState, } from "react";
import { ACTION_CONST, ACTION_STATUS } from "../constants";
import { useDispatch } from "react-redux";
import { helpers } from "../utils/helpers";
import { useProjectName, useProjectSymbol, useSelectedProject, useWeb3Utils } from "../../hook/useState";
import { Button, Modal } from "react-bootstrap";
import { toast } from 'react-toastify';

const ApproveModal = (props) => {
  const dispatch = useDispatch();
  const selectedProject = useSelectedProject();
  const web3Utils = useWeb3Utils();
  const [amount, setAmount] = useState(0);
  const projectName = useProjectName();
  const symbol = useProjectSymbol();
  const [enableAprBtn, setEnableAprBtn] = useState(false);

  const handleOnchangeAmount = (e) => {
    const amountInputValue = Number(e.target.value);
    if (0 < amountInputValue
      && amountInputValue <= props.walletInfo.remainingAllocation
      && amountInputValue <= props.walletInfo.tokenBalance) {
      setEnableAprBtn(true);
    } else {
      setEnableAprBtn(false)
    }
    setAmount(amountInputValue)
    props.handleInputAmount(amountInputValue)
    return;
  }

  const handleClickMax = () => {
    if (props.walletInfo.tokenBalance > 0 && props.walletInfo.remainingAllocation <= props.walletInfo.tokenBalance) {
      setAmount(helpers.formatNumberDownRound(props.walletInfo.remainingAllocation, 6))
      document.getElementById('inputAmountApprove').value = helpers.formatNumberDownRound(props.walletInfo.remainingAllocation, 6);
      setEnableAprBtn(true);
      return;
    }
    if (props.walletInfo.remainingAllocation > 0 && props.walletInfo.remainingAllocation > props.walletInfo.tokenBalance) {
      setAmount(helpers.formatNumberDownRound(props.walletInfo.tokenBalance, 6))
      document.getElementById('inputAmountApprove').value = helpers.formatNumberDownRound(props.walletInfo.tokenBalance, 6)
      setEnableAprBtn(true);
      return;
    }
    setEnableAprBtn(false);
  }

  const handleApprove = () => {
    if (web3Utils && selectedProject) {
      dispatch({
        type: ACTION_CONST.REQUEST_SUBMIT
      })
      web3Utils.approve({
        contractAddress: selectedProject.contract,
        tokenContractAddress: selectedProject.tokenAddress,
        amount: Number(amount)
      }, (data) => {
        if (data.status === ACTION_STATUS.APPROVED) {
          dispatch({ type: ACTION_CONST.REQUEST_DONE })
          props.handleBuyClick()
          // document.getElementById("inputAmountApprove").value = 0;
          setEnableAprBtn(false);
          toast.success("Approve Success");
        }
        if (data.status == ACTION_STATUS.APPROVE_FAILS) {
          dispatch({ type: ACTION_CONST.REQUEST_DONE })
          toast.error("Failed to Approve Tokens");
        }
      }).catch(err => {
        toast.error("Token Approve Fails! Please try again!");
        dispatch({ type: ACTION_CONST.REQUEST_DONE })
      })
      props.onDismiss();
    }
  }

  return <Modal className='gz-modal approve-modal' show={true} onHide={props.onDismiss} centered>
    <Modal.Header closeButton>
      <Modal.Title>Join {projectName} Pool</Modal.Title>
    </Modal.Header>
    <Modal.Body className="px-5 pt-4 pb-3">
      <div className="gx-form-label">{symbol} Amount</div>
      <div className="gz-input-group">
        <input type="number" placeholder={0.0} defaultValue={0} autoFocus onChange={(e) => handleOnchangeAmount(e)} id="inputAmountApprove" />
        <span style={{ cursor: 'pointer' }} onClick={handleClickMax}>MAX</span>
      </div>
      <div className="gz-form-balance">Your balance: <span>{props?.tokenBalance} {props?.symbol}</span></div>
      <div className="mt-5">
        <Button className="mx-auto" disabled={!enableAprBtn} onClick={() => handleApprove()}>
          <span>Approve</span>
        </Button>
      </div>
    </Modal.Body>
  </Modal>;
};

export default ApproveModal;




