import { BigNumber } from "bignumber.js";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, ProgressBar, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { useActiveWeb3React } from "../../hook";
import GZCard from "../../shared/components/card";
import { useBnbBalance, useIsConnectWallet, usePadTokenBalance, useStakingInfo, useStakingWalletInfo, useWeb3Utils } from "../../shared/components/hook/useSate";
import StakeInfo from "../../shared/components/StakeInfo";
import { ACTION_CONST, ROUTES, STATUS } from "../../shared/constants";
import { helpers } from "../../shared/utils";
import { BSC_EXPLORER, BSC_GZONE_ADDRESS, STAKING_CONTRACT_ADDRESS } from "../../_configs";

const StakingPage = () => {
    const dispatch = useDispatch();
    const { account, chainId } = useActiveWeb3React();
    const isConnectWallet = useIsConnectWallet();
    const walletUtils = useWeb3Utils();
    const stakingInfo = useStakingInfo()
    const stakingWalletInfo = useStakingWalletInfo();
    const bnbBalance = useBnbBalance();
    const bscPadBalance = usePadTokenBalance();
    const [acceptTerm, setAcceptTerm] = useState(false);
    const [swapAmount, setSwapAmount] = useState('0')
    const [isMaxAmount, setIsMaxAmount] = useState(false);
    const [swapCurrentStep, setSwapCurrentStep] = useState(1);
    const [enableSwapBtn, setEnableSwapBtn] = useState(false);
    const [transactionHash, setTransactionHash] = useState("")
    const [isSubmitOK, setIsSubmitOK] = useState(false);

    useEffect(() => {
        if (swapCurrentStep === 1) {
            if (acceptTerm &&
                isConnectWallet &&
                !stakingInfo["isStakingPaused"] &&
                parseFloat(bscPadBalance) > 0 &&
                bnbBalance > 0 &&
                stakingWalletInfo["withdrawTimestamp"] === 0
            ) {
                setEnableSwapBtn(true);
            } else {
                setEnableSwapBtn(false);
            }
        }
    }, [acceptTerm, isConnectWallet, bnbBalance, bscPadBalance, stakingInfo, stakingWalletInfo, swapCurrentStep])

    const swapBack = () => {
        if (swapCurrentStep === 1) {
            return;
        } else {
            setSwapCurrentStep(swapCurrentStep - 1);
        }
    }

    const swapNext = async () => {
        if (swapCurrentStep === 5) return;
        if (swapCurrentStep === 1 && parseFloat(swapAmount) === 0) {
            setEnableSwapBtn(false);
            setSwapCurrentStep(swapCurrentStep + 1);
            return;
        }
        if (swapCurrentStep === 3) {
            if (walletUtils) {
                dispatch({
                    type: ACTION_CONST.REQUEST_SUBMIT
                });
                let amount = isMaxAmount ? bscPadBalance : swapAmount
                const allowance = await walletUtils.getAllowance(BSC_GZONE_ADDRESS[chainId], STAKING_CONTRACT_ADDRESS[chainId]);
                if (BigNumber(allowance).gte(BigNumber(amount))) {
                    setSwapCurrentStep(4);
                    dispatch({
                        type: ACTION_CONST.REQUEST_DONE,
                    });
                    return;
                }
                walletUtils.approve({ tokenContractAddress: stakingInfo["tokenAddr"], contractAddress: STAKING_CONTRACT_ADDRESS[chainId], amount: amount }, (data) => {
                    if (data.status === STATUS.APPROVED) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        });
                        toast.success("Approve Tokens successfully!");
                        setSwapCurrentStep(4);
                    }
                    if (data.status === STATUS.APPROVE_FAILS) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })
                        toast.error("Failed to Approve Tokens!");
                    }
                })
            }
        } else if (swapCurrentStep === 4) {
            if (walletUtils) {
                dispatch({
                    type: ACTION_CONST.REQUEST_SUBMIT
                })
                walletUtils.stakingDeposit({ amount: isMaxAmount ? bscPadBalance : swapAmount }, (result) => {
                    if (result.status === STATUS.STAKING_DEPOSIT_SUCCESS) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })
                        setIsSubmitOK(true)
                        setTransactionHash(result.txID)
                        setSwapCurrentStep(5);
                    }
                    if (result.status === STATUS.STAKING_DEPOSIT_FAIL) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })
                        toast.error("Deposit stake fail!");
                    }
                })
            }
        } else {
            setSwapCurrentStep(swapCurrentStep + 1);
        }
    }

    const handleInputSwap = (e) => {
        setIsMaxAmount(false);
        if (helpers.isFloatFormatted(e.target.value, 4)) {
            setSwapAmount(e.target.value);
            if (BigNumber(e.target.value).lte(BigNumber(bscPadBalance)) && BigNumber(e.target.value).gt(BigNumber(0))) {
                setEnableSwapBtn(true);
            } else {
                setEnableSwapBtn(false);
            }
        }
    }

    const submitDone = () => {
        setSwapAmount('0');
        setIsMaxAmount(true);
        setSwapCurrentStep(1);
        setIsSubmitOK(false);
    }

    const handleMaxButtonClick = () => {
        setSwapAmount(helpers.formatNumberDownRound(bscPadBalance, 4));
        setEnableSwapBtn(true);
        setIsMaxAmount(true);
    }

    return (
        <div className="gz-stake-page">
            <Container>
                <StakeInfo />
                <div className="gz-page-title">Stake your $GZONE</div>
                <div className="gz-stepper">
                    <div className={`gz-stepper-item ${swapCurrentStep >= 1 ? 'active' : ''}`}>
                        <span>Checkpoints</span>
                    </div>
                    <div className={`gz-stepper-item ${swapCurrentStep >= 2 ? 'active' : ''}`}>
                        <span>Enter Amount</span>
                    </div>
                    <div className={`gz-stepper-item ${swapCurrentStep >= 3 ? 'active' : ''}`}>
                        <span>Pre-authorization</span>
                    </div>
                    <div className={`gz-stepper-item ${swapCurrentStep >= 4 ? 'active' : ''}`}>
                        <span>Confirm</span>
                    </div>
                    <div className={`gz-stepper-item ${swapCurrentStep >= 5 ? 'active' : ''}`}>
                        <span>Confirmation</span>
                    </div>
                </div>
                <div className="gz-st-body">
                    {swapCurrentStep === 1 &&
                        <div className="gz-st-step" id="SwapStep1">
                            <div className="gz-st-step-title">Checkpoints</div>
                            <div className="gz-st-step-description">The following conditions must be met to proceed</div>
                            <div className="gz-st-step-body">
                                <GZCard className="pt-3">
                                    <Row className="gx-xl-5">
                                        <Col xl={3} md={6} className="mb-xl-0 mb-5">
                                            <div className={`gz-st-step-item ${isConnectWallet ? 'selected' : ''}`} >
                                                <div className="gz-st-step-item-num">01</div>
                                                <div className="gz-st-step-item-title">Connected MetaMask</div>
                                                <div className="gz-st-step-item-body">
                                                    If not connected, click the "Connect Wallet" button in the top right corner
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={3} md={6} className="mb-xl-0 mb-5">
                                            <div className={`gz-st-step-item ${parseFloat(bscPadBalance) > 0 ? 'selected' : ''}`} >
                                                <div className="gz-st-step-item-num">02</div>
                                                <div className="gz-st-step-item-title">$GZONE available</div>
                                                <div className="gz-st-step-item-body">
                                                    Current Balance: {helpers.formatNumberDownRoundWithExtractMax(bscPadBalance, 4)}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={3} md={6} className="mb-xl-0 mb-5">
                                            <div className={`gz-st-step-item ${bnbBalance > 0 ? 'selected' : ''}`} >
                                                <div className="gz-st-step-item-num">03</div>
                                                <div className="gz-st-step-item-title">BNB available</div>
                                                <div className="gz-st-step-item-body">
                                                    BNB is required to pay transaction fees on the Binance Smart Chain network.<br />
                                                    BNB Balance: {helpers.formatNumberDownRoundWithExtractMax(bnbBalance, 4)}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={3} md={6} className="mb-xl-0 mb-5">
                                            <div className={`gz-st-step-item ${stakingWalletInfo["withdrawTimestamp"] === 0 ? 'selected' : ''}`} >
                                                <div className="gz-st-step-item-num">04</div>
                                                <div className="gz-st-step-item-title">Eligible to stake</div>
                                                <div className="gz-st-step-item-body">
                                                    You cannot stake if you have an active $GZONE unstake/withdrawal request
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <div className="pt-5">
                                        <div className="gz-form-check form-check">
                                            <input className="form-check-input float-none me-1"
                                                type="checkbox" defaultValue id="flexCheckDefault"
                                                onChange={() => setAcceptTerm(!acceptTerm)} />
                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                I have read the&nbsp;
                                                <Link target="_blank" to={ROUTES.TERM_OF_USES}>Terms and Conditions </Link>
                                            </label>
                                        </div>
                                    </div>
                                </GZCard>
                            </div>
                        </div>
                    }

                    {swapCurrentStep === 2 &&
                        <div className="gz-st-step" id="SwapStep2">
                            <div className="gz-st-step-title">Amount Stake</div>
                            <div className="gz-st-step-description">Please enter the amount of $GZONE you want to stake</div>
                            <div className="gz-st-step-note">Any fees applied at Unstake &amp; Withdraw stage will be based on the date you last staked.</div>
                            <div className="gz-st-step-body">
                                <div className="mx-auto" style={{ maxWidth: '92%', width: 500 }}>
                                    <GZCard className="pt-3 text-start">
                                        <div className="gx-form-label">Amount</div>
                                        <div className="gz-input-group">
                                            <input type="number" placeholder={0.0} value={swapAmount} onChange={(e) => handleInputSwap(e)} />
                                            <span style={{ cursor: 'pointer' }} onClick={handleMaxButtonClick}>MAX</span>
                                        </div>
                                        <div className="gz-form-balance">Balance: <span>{helpers.formatNumberDownRound(bscPadBalance, 4)}</span></div>
                                    </GZCard>
                                </div>
                            </div>
                        </div>
                    }

                    {swapCurrentStep === 3 &&
                        <div className="gz-st-step" id="SwapStep3">
                            <div className="gz-st-step-title">Pre-authorization</div>
                            <div className="gz-st-step-note">Required transaction 1 of 2</div>
                            <div className="gz-st-step-description">In this step, you grant access to the staking smart contract to accept your $GZONE</div>

                            <div className="gz-st-step-body">
                                <div className="gz-progress">
                                    <div className="gz-progress-percent">
                                        <ProgressBar animated striped now={50} />
                                    </div>
                                </div>
                                {/* <h4 className="mb-2 pb-1 mt-5">Waiting for the transaction to complete</h4> */}
                                <p>Please wait for the transaction to confirm before proceeding.</p>
                                <a className="gz-address" href={`${BSC_EXPLORER[chainId]}/address/${account}`} target="blank">{account}</a>
                            </div>
                        </div>
                    }

                    {swapCurrentStep === 4 &&
                        <div className="gz-st-step" id="SwapStep4">
                            <div className="gz-st-step-title">Confirm</div>
                            <div className="gz-st-step-note">Required transaction 2 of 2</div>
                            <div className="gz-st-step-description">
                                In this step, you deposit the tokens into the staking contract.<br />
                                After this step, your tokens will be successfully staked.
                            </div>
                        </div>
                    }

                    {swapCurrentStep === 5 &&
                        <div className="gz-st-step" id="SwapStep5">
                            <div className="gz-st-step-title">Success</div>
                            <div className="gz-st-step-description">
                                Congratulations! Your tokens are now staked.<br />
                                If desired, you may check Binance Smart Chain to confirm the transaction.
                            </div>
                            <div className="gz-st-step-body">
                                <a className="gz-address" href={`${BSC_EXPLORER[chainId]}/tx/${transactionHash}`} target="blank">{transactionHash}</a>
                            </div>
                        </div>
                    }
                </div>
            </Container>

            <div className="gz-st-step-actions my-5 pb-5 d-flex justify-content-center" style={{ columnGap: '10px' }}>
                {
                    !isSubmitOK ?
                        <>
                            <Button onClick={() => swapBack()} disabled={!enableSwapBtn || swapCurrentStep === 1}>
                                <span><i className="mdi mdi-arrow-left me-2" />Previous</span>
                            </Button>
                            <Button onClick={() => swapNext()} disabled={!enableSwapBtn || swapCurrentStep === 5}>
                                <span>Next<i className="mdi mdi-arrow-right ms-2" /></span>
                            </Button>
                        </>
                        : <Button onClick={() => submitDone()}><span>Done</span></Button>
                }
            </div>
        </div>
    );
};

export default StakingPage;
