import React from 'react';

const GZCard = ({ title, children, className = '' }) => {
    return <div className={`gz-card ${className}`}>
        {title && (<div className="gz-card-title">{title}</div>)}
        <div className="gz-card-body">
            {children}
        </div>
    </div>
}
export default GZCard