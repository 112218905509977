

export const MODE = process.env.REACT_APP_MODE || "MAINNET";

export const BSC_EXPLORER = {
  56: "https://bscscan.com",
  97: "https://testnet.bscscan.com",
};



export const STAKING_CONTRACT_ADDRESS = {
  56: "0xC5769c1e3bf4e6CaAA9f395b2F54695526Aa047b",
  97: "0xe0b0C16038845BEd3fCf70304D3e167Df81ce225",
};

export const BSC_GZONE_ADDRESS = {
  56: "0x5A726a26eDB0Df8Fd55f03cc30aF8A7cEa81e78D",
  97: "0xe0d24e7695fd9dcc57a097388811fbaeece68ce7",
};

export const POOL_INTERVAL = 15000;



// export const BACKEND_URI = "https://t.nftlaunch.network";

export const BACKEND_URI = 'https://app.gamezone.io'

export const NODE_URI = {
  56: [
    "https://bsc-dataseed.binance.org/",
    "https://bsc-dataseed1.defibit.io/",
  ],
  97: ["https://data-seed-prebsc-1-s2.binance.org:8545/"]
  // 1: ['https://mainnet.infura.io/v3/e6d8a9c75b2e4e8c9d1e4c57446ffded']
};

export const KYC_BACK_END_URI = "https://bscpad.com";

export const chainIdsSupport = [56];

export const defaultChainId = 56
// process.env.REACT_APP_MODE === "MAINNET" ? 56 : 97;

export const showKYC = 1000.0



