import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import { useActiveWeb3React } from "../../hook";
import { useLatestBlockNumber } from "../../hook/useState";
import { ACTION_CONST, STATUS } from "../constants";
import { helpers } from "../utils";
import { getStakingContractInfo } from "../utils/bscHelpers";
import GZCard from "./card";
import { useIsConnectWallet, useStakingInfo, useSubmit, useWeb3Utils } from "./hook/useSate";

const StakeInfo = () => {
  const dispatch = useDispatch();
  const { account } = useActiveWeb3React();
  const isConnectWallet = useIsConnectWallet();
  const walletUtils = useWeb3Utils()
  const [stakedAmount, setStakedAmount] = useState('0');
  const [rewardAmount, setRewardAmount] = useState('0');
  const [withdrawTimestamp, setWithdrawTimestamp] = useState(0);
  const submitOK = useSubmit();
  const latestBlock = useLatestBlockNumber();
  const stakingInfo = useStakingInfo();
  const stakerCount = get(stakingInfo, 'stakerCount', 0);
  const apyPercentage = get(stakingInfo, 'apyPercentage', 0);
  const totalStakedAmount = get(stakingInfo, 'totalStakedAmount', 0);

  useEffect(() => {
    if (walletUtils && account) {
      walletUtils.getStakingInfoWallet().then(data => {
        setStakedAmount(data.stakedAmount);
        setRewardAmount(data.rewardAmount);
        const now = new Date().getTime();
        if (data.withdrawTimestamp > now) {
          setWithdrawTimestamp(data.withdrawTimestamp);
        }
        dispatch({ type: ACTION_CONST.GET_STAKING_WALLET_INFO, data: data })
      });
    }

  }, [walletUtils, account, submitOK, dispatch, withdrawTimestamp, latestBlock])

  useEffect(() => {

    getStakingContractInfo().then(data => {

        dispatch({ type: ACTION_CONST.GET_STAKING_INFO, data: data });
    })


}, [dispatch, submitOK, latestBlock]);

  const handleExcWithDraw = () => {
    if (walletUtils) {
      dispatch({
        type: ACTION_CONST.REQUEST_SUBMIT
      })
      walletUtils.stakingExecuteWithdrawRewards(data => {
        if (data.status === STATUS.STAKING_EXECUTE_WITHDRAW_REWARDS_SUCCESS) {
          dispatch({
            type: ACTION_CONST.REQUEST_DONE
          })
          toast.success("Withdraw stake successfully");
        }
        if (data.status === STATUS.STAKING_EXECUTE_WITHDRAW_REWARDS_FAIL) {
          dispatch({
            type: ACTION_CONST.REQUEST_DONE
          })
          toast.error("Withdraw stake fail");
        }
      })
    }
  }

  const handleExStakeRewards = async () => {
    if (walletUtils) {
      dispatch({
        type: ACTION_CONST.REQUEST_SUBMIT
      })
      walletUtils.stakingRewards(data => {
        if (data.status === STATUS.STAKING_REWARDS_SUCCESS) {
          dispatch({
            type: ACTION_CONST.REQUEST_DONE
          })
          toast.success("Stake rewards successfully");
        }
        if (data.status === STATUS.STAKING_REWARDS_FAIL) {
          dispatch({
            type: ACTION_CONST.REQUEST_DONE
          })
          toast.error("Stake rewards fail");
        }
      })
    }
  }

  return (
    <div className="gz-st-info">
      <Row className="mb-4">
        <Col lg={4} md={6} className="mb-4 mb-lg-0">
          <GZCard title="Number of Stakers">
            <h3>{stakerCount}</h3>
          </GZCard>
        </Col>
        <Col lg={4} md={6} className="mb-4 mb-lg-0">
          <GZCard title="Total $GZONE Staked">
            <h3>{helpers.formatNumberDownRound(totalStakedAmount, 2)}</h3>
          </GZCard>
        </Col>
        <Col lg={4} md={6} className="mb-4 mb-lg-0">
          <GZCard title="APY">
            <h3>{helpers.formatNumberDownRound((apyPercentage / 100).toString(), 2)}%</h3>
          </GZCard>
        </Col>
        <Col xl={4} lg={3} md={6} className="mb-4 mb-lg-0 mt-lg-4 mt-0">
          <GZCard title="Staked">
            <h3>{helpers.formatNumberDownRoundWithExtractMax(stakedAmount, 4)}</h3>
          </GZCard>
        </Col>
        <Col xl={8} lg={9} className="mt-lg-4 mt-0">
          <GZCard title="Rewards">
            <Row>
              <Col md={4} className="mb-4 mb-md-0">
                <h3>{helpers.formatNumberDownRoundWithExtractMax(rewardAmount, 4)}</h3>
              </Col>
              <Col md={8}>
                <div className="d-flex justify-content-md-end" style={{ columnGap: 10 }}>
                  <Button disabled={!isConnectWallet || parseFloat(rewardAmount) === 0} onClick={() => handleExStakeRewards()}>
                    <span>Stake</span>
                  </Button>
                  <Button disabled={!isConnectWallet || parseFloat(rewardAmount) === 0} onClick={() => handleExcWithDraw()}>
                    <span>Withdraw</span>
                  </Button>
                </div>
              </Col>
            </Row>
          </GZCard>
        </Col>
      </Row>
    </div>
  );
};

export default StakeInfo;
