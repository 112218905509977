import React, { useEffect } from "react";
import { Container, Image } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants";
import { useLatestBlockNumber } from "../../hook/useState";
import { actGetListProjects } from "../../redux/action/user";
import ClosedProjectsComponent from "./components/ClosedProjectsComponent";
import OpeningProjectsComponent from "./components/OpeningProjectsComponent";
import WaitingProjectsComponent from "./components/WaitingProjectsComponent";

const ProjectsPage = () => {
    const dispatch = useDispatch();
    const latestBlock = useLatestBlockNumber();

    useEffect(() => {
        dispatch(actGetListProjects())
    }, [latestBlock, dispatch])

    return <div className="gz-projects-page">
        <section className="gz-projects-banner">
            <Image src="/images/bg.png" className="w-100" alt="" />
            <Container>
                <div className="d-flex justify-content-center flex-wrap" style={{ columnGap: 12, marginTop: '-13%', paddingBottom: '13%' }}>
                    <a href="https://gamezone.io/" target="_blank" rel="noopener noreferrer" className="btn btn-primary">
                        <span>Homepage</span>
                    </a>
                    <Link to={ROUTES.STACKING} className="btn btn-primary">
                        <span>Staking</span>
                    </Link>
                    <a href="https://hub.gamezone.io" rel="noopener noreferrer" className="btn btn-primary btn-full-mb">
                        <span>Gamezone Hub</span>
                    </a>
                    <a href="https://fiat2bnb.com/?integration=8&embed=1" target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-full-mb">
                        <span>Buy with fiat</span>
                    </a>
                </div>
            </Container>
        </section>
        <OpeningProjectsComponent />
        <WaitingProjectsComponent />
        <ClosedProjectsComponent />
    </div>
}
export default ProjectsPage;