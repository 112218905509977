import moment from 'moment';
import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import { ACTION_CONST } from "../../../constants";
import { useWeb3Utils } from "../../../hook/useState";
import GZCard from "../../../shared/components/card";
import { formatTimeStampAllocation, formatTokenAllocation, helpers } from "../../../shared/utils/helpers";
import { addTokenToMetamask } from '../../../shared/utils/metamaskUtils';
import { isMetamaskAvailable } from "../../../shared/utils/utils";

const YourAllocationComponent = (props) => {
    const dispatch = useDispatch();
    const web3Utils = useWeb3Utils();
    const [layout, setLayout] = useState(1);
    const [allocationInfo, setAllocation] = useState([])

    useEffect(() => {
        if (props) {
            setAllocation(props.allocationInfo)
            setLayout(props.layout)
        }
    }, [props])

    const handleClaimClick = (index) => {
        if (web3Utils) {
            dispatch({
                type: ACTION_CONST.REQUEST_SUBMIT
            })
            web3Utils.claim({
                contractAddress: props.contractAddress,
                index: index
            }, (data) => {
                if (data.status == "CLAIM_SUCCESS") {
                    dispatch({ type: ACTION_CONST.REQUEST_DONE })
                    toast.success("Tokens Successfully Claimed");
                    props.handleBuyClick()
                }
                if (data.status == "CLAIM_FAIL") {
                    dispatch({ type: ACTION_CONST.REQUEST_DONE })
                    toast.error("Failed to claim tokens, Please contact support");
                    props.handleBuyClick()
                }
            })
        }
    }

    const handleAddTokenToMetamask = async () => {
        if (props.tokenAddress) {
            addTokenToMetamask({
                tokenAddress: props.tokenAddress,
                tokenSymbol: props.tokenSymbol,
                tokenDecimals: props.decimals,
                tokenImage: ""
            }, (res) => {
                if (res.status == "ADD_TOKEN_SUCCESS") {
                    toast.success("Successfully added token to MetaMask");
                }
                if (res.status == "ADD_TOKEN_FAILS") {
                    toast.error("Failed to add token to MetaMask");
                }
            })
        } else {
            toast.error("Token incorrect!");
        }
    }

    return (
        <div className="my-5 pb-5">
            <div className="d-flex flex-wrap justify-content-center justify-content-md-end" style={{ rowGap: '20px' }}>
                <div>
                    {
                        props.allocationInfo.length >= 0 &&
                        <Button onClick={() => handleAddTokenToMetamask()}>
                            <span>Add token to MetaMask</span>
                        </Button>
                    }
                </div>
            </div>
            <GZCard title="Your Allocation" className='gz-allowcation'>
                <div className="d-lg-none d-block mt-4">
                    <Row>
                        {
                            (allocationInfo.length > 0 && layout === 1) &&
                            allocationInfo.map((item, key) => (
                                <Col md={6} key={key}>
                                    <div className="allocation-card">
                                        <div>#{item.no}</div>
                                        <div>
                                            <span>Allocation</span>
                                            <h4>{helpers.formatNumberDownRoundWithExtractMax((item.allocationAmount / 10 ** props.decimals || 0), 4)} {props.tokenSymbol}</h4>
                                        </div>
                                        <div>
                                            <span>Date</span>
                                            <h4>{(item.timestamp !== 0 && item.timestamp !== "0") ?
                                                <div>{moment(item.timestamp * 1000 || 0).utc().format('YYYY-MM-DD HH:mm:ss')} UTC </div>
                                                : "DEX Listing"
                                            }</h4>
                                        </div>
                                        <div>
                                            <span>Claimed</span>
                                            <h4>{helpers.formatNumberDownRoundWithExtractMax((item.claimedAmount / 10 ** props.decimals || 0), 4)} {props.tokenSymbol}</h4>
                                        </div>
                                        <div>
                                            <Button disabled={!(item.status === "OPEN")} onClick={() => handleClaimClick(key)} className="w-100 mb-0">
                                                <span>Claim Tokens</span>
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            ))
                        }
                        {
                            (allocationInfo.length > 0 && layout === 2) &&
                            allocationInfo.map((item, key) => (
                                <Col md={6} key={key}> 
                                    <div className="allocation-card">
                                        <div>#{item.no}</div>
                                        <div>
                                            <span>Allocation</span>
                                            <h4>{formatTokenAllocation(item.allocationAmount, props.decimals, 4)}</h4>
                                        </div>
                                        <div>
                                            <span>Percentage</span>
                                            <h4>{(item.percentage / 100).toFixed(2)}%</h4>
                                        </div>
                                        <div>
                                            <span>Date</span>
                                            <h4>{(item.timestamp !== 0 && item.timestamp !== "0") ?
                                                <div dangerouslySetInnerHTML={{ __html: formatTimeStampAllocation(item.timestamp) }}></div>
                                                : "DEX Listing"
                                            }</h4>
                                        </div>
                                        <div>
                                            <span>Claimed</span>
                                            <h4>{helpers.formatNumberDownRoundWithExtractMax((item.claimedAmount / 10 ** props.decimals || 0), 4)}</h4>
                                        </div>
                                        <div>
                                            <Button disabled={!(item.status === "OPEN")} onClick={() => handleClaimClick(key)} className="w-100 mb-0">
                                                <span>Claim Tokens</span>
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                </div>
                <div className="d-none d-lg-block">
                    <Table responsive className="mb-0" style={{ verticalAlign: 'middle' }}>
                        <tbody>
                            <tr className="card-header" style={{ border: "none" }}>
                                <td>No.</td>
                                <td>Allocation</td>
                                {
                                    layout === 2 &&
                                    <td>Percentage</td>
                                }
                                <td>Date</td>
                                <td>Claimed</td>
                                {isMetamaskAvailable() &&
                                    <td className='text-center' style={{ width: 180 }}>Action</td>
                                }
                            </tr>
                            {
                                (allocationInfo.length > 0 && layout === 1) &&
                                allocationInfo.map((item, key) => (
                                    <tr key={key}>
                                        <td>
                                            <h5 className="mb-0">{item.no}</h5>
                                        </td>
                                        <td>
                                            <h5 className="mb-0">{helpers.formatNumberDownRoundWithExtractMax((item.allocationAmount / 10 ** props.decimals || 0), 4)} {props.tokenSymbol}</h5>
                                        </td>
                                        <td>
                                            <h5 className="mb-0">
                                                {(item.timestamp !== 0 && item.timestamp !== "0") ?
                                                    <div>{moment(item.timestamp * 1000 || 0).utc().format('YYYY-MM-DD HH:mm:ss')} UTC </div>
                                                    : "DEX Listing"
                                                }
                                            </h5>
                                        </td>
                                        <td>
                                            <h5 className="mb-0">{helpers.formatNumberDownRoundWithExtractMax((item.claimedAmount / 10 ** props.decimals || 0), 4)} {props.tokenSymbol}</h5>
                                        </td>
                                        <td>
                                            <Button className="btn-claim mb-0" disabled={!(item.status === "OPEN")} onClick={() => handleClaimClick(key)}>
                                                <span>Claim Tokens</span>
                                            </Button>
                                        </td>
                                    </tr>
                                ))
                            }
                            {
                                (allocationInfo.length > 0 && layout === 2) &&
                                allocationInfo.map((item, key) => (
                                    <tr key={key}>
                                        <td>
                                            <h5 className="mb-0">{item.no}</h5>
                                        </td>
                                        <td>
                                            <h5 className="mb-0">{formatTokenAllocation(item.allocationAmount, props.decimals, 4)}</h5>
                                        </td>
                                        <td>
                                            <h5 className="mb-0">{(item.percentage / 100).toFixed(2)}%</h5>
                                        </td>
                                        <td>
                                            <h5 className="mb-0">
                                                {(item.timestamp !== 0 && item.timestamp !== "0") ?
                                                    <div dangerouslySetInnerHTML={{ __html: formatTimeStampAllocation(item.timestamp) }}></div>
                                                    : "DEX Listing"
                                                }
                                            </h5>
                                        </td>
                                        <td>
                                            <h5 className="mb-0">{helpers.formatNumberDownRoundWithExtractMax((item.claimedAmount / 10 ** props.decimals || 0), 4)}</h5>
                                        </td>
                                        <td>
                                            <Button className="btn-claim mb-0" disabled={!(item.status === "OPEN")} onClick={() => handleClaimClick(key)}>
                                                <span>Claim Tokens</span>
                                            </Button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </div>
            </GZCard>
        </div>
    );
};

export default YourAllocationComponent;
