import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import { useActiveWeb3React } from '../../hook';
import useAuth from '../../hook/useAuth';
import { BSC_EXPLORER } from '../../_configs';
import useCopyToClipboard from '../components/hook/CopyToClibboard';
import { ACTION_CONST } from '../constants';

const WalletModal = ({ onDismiss }) => {
    const dispatch = useDispatch();
    const [copied, setCopied] = useCopyToClipboard(1000);
    const { logout } = useAuth();
    const { account, chainId } = useActiveWeb3React()

    const handleLogout = () => {
        logout();
        localStorage.clear();
        dispatch({
            type: ACTION_CONST.LOG_OUT_WALLET_SUCCESS
        })
        onDismiss();
        window.location.reload();
    }

    const handleCopyWalletAddress = (e)=>{
        e.preventDefault();
        setCopied(account);
    }

    return (
        <>
            <Modal className='gz-modal your-wallet' show={true} onHide={onDismiss} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Your wallet</Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-5 pt-4 pb-3">
                    <div className="mb-3 text-center">
                        <h5 style={{ fontSize: '20px', wordBreak: 'break-word', lineHeight: 1.3 }} className="mb-3"><b>{account}</b></h5>
                        <a href={`${BSC_EXPLORER[chainId]}/address/${account}`} target="_blank" className="d-inline-flex align-items-center me-4" style={{ textDecoration: 'none' }}><span className="me-1">View on BscScan</span><i className="mdi mdi-open-in-new"></i></a>
                        <a className="d-inline-flex align-items-center" href="#" onClick={handleCopyWalletAddress} style={{ textDecoration: 'none' }}>
                            <span className="me-1">Copy Address</span>{!copied ? <i className="mdi mdi-content-copy"></i> : <i className="mdi mdi-check"></i>}
                        </a>
                    </div>
                    <div className="d-flex justify-content-center mt-4">
                        <Button onClick={handleLogout}>
                            <span>Logout</span>
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default WalletModal;


