import { get } from "lodash";
import { useSelector } from "react-redux";

export const useIsConnectWallet = ()=>{
    return  useSelector((state) => get(state, "utils.isConnectWallet", false));
}

export const useWeb3Utils = ()=>{
   return useSelector((state) => get(state, "utils.walletUtils", null));
}

export const useStakingInfo = () =>{
    return  useSelector((state) => get(state, "wallet.stakingInfo", {}))
}

export const useStakingWalletInfo = () =>{
    return useSelector((state) => get(state, "wallet.stakingWalletInfo", {}))
}

export const useBnbBalance = () =>{
  return   useSelector((state) => get(state, "wallet.bnbBalance", 0));
}

export const usePadTokenBalance = () =>{
    return   useSelector((state) => get(state, "wallet.bscpadBalance", 0));  
}

export const useSubmit = () =>{
    return  useSelector((state) =>get(state, "utils.blocking", false));
}

export const useStakeAmount = ()=>{
    return useSelector((state) => get(state, "wallet.stakingWalletInfo.stakeAmount", 0));
}